export default {
  SET_COURSES(state, data) {
    state.courses = data;
  },
  SET_PENDING_COURSES(state, data) {
    state.pendingCourses = data;
  },
  SET_PENDING_COURSES_COUNT (state, data) {
    state.pendingCoursesCount = data.pending_courses_count
  }
}