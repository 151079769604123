<template>
    <div class="home">
        <notifications group="public" position="top center" class="mt-2"/>
        <splash-screen></splash-screen>
        <nav-bar></nav-bar>
        <content-bar></content-bar>
        <transition name="fade-effect" mode="out-in" appear>
        <router-view></router-view>
        </transition>
        <MainFooter></MainFooter>
    </div>
</template>

<script>
    // @ is an alias to /src
    import NavBar from "../core/NavBar/NavBar";
    import MainFooter from "../core/Footer/MainFooter/MainFooter";
    import SplashScreen from "../core/SplashScreen/SplashScreen";
    import ContentBar from "../core/ContentBar/ContentBar";

    export default {
        name: 'Main',
        components: {ContentBar, SplashScreen, MainFooter, NavBar},
    }
</script>
<!-- we will put all shared style in this component-->
<style >
    .btn-primary-hover:hover {
        color: var(--primary-color) !important;
    }
    .vue-notification-template{
      height: max-content !important;
      padding: 15px !important;
      font-size: 15px !important;
      text-align: center !important;
    }
    .fade-effect-enter {
        opacity: 0;
        transform: translate(20px , -20px);
    }

    .fade-effect-leave-to {
        opacity: 0;
        transform: translate(20px , -20px);
    }

    .fade-effect-enter-active,
    .fade-effect-leave-active {
        transition: 0.6s ease-in-out;
    }
</style>
