import Request from '@/core/Classes/Request';
import { loginApi } from './authAdmin.api';
import BaseService from '@/core/Classes/BaseService';

export default class AuthAdminService extends BaseService {
  static login(data) {
    return new Promise((resolve, reject) => {
      Request.post(loginApi, data).then(res => {
        if (res.status < 300) {
          resolve(res);
        } else {
          reject(res);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }
}
