const mutations = {
  /**
   * @param state
   * @param userInfos
   * @desc SET_USER_INFOS mutation
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  SET_USER_INFOS(state, userInfos) {
    state.userInfos = userInfos;
  },

  /**
   * @param state
   * @param allWishes
   * @desc SET_ALL_WISHES mutation
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  SET_ALL_WISHES(state, allWishes) {
    state.allWishes = allWishes;
  },

  /**
   * @param state
   * @param allWishes
   * @desc REMOVE_WISH mutation
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  REMOVE_WISH(state, id) {
    state.allWishes = state.allWishes.filter((e) => e.course_id != id);
  },

  /**
   * @param state
   * @param studentCourses
   * @desc SET_STUDENT_COURSES mutation
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  SET_STUDENT_COURSES(state, studentCourses) {
    state.studentCourses = studentCourses;
  },

  /**
   * @param state
   * @param bills
   * @desc SET_BILLS mutation
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  SET_BILLS(state, bills) {
    state.bills = bills;
  }
};

export default mutations;
