import state from "./modulesCourses.state";
import getters from "./modulesCourses.getters";
import mutations from "./modulesCourses.mutationTypes";
import actions from "./modulesCourses.actions";
export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
