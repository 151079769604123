import Request from "../../../../../core/Classes/Request";
import { addFAQApi, FAQsCount, getFAQsApi, editFAQsApi } from "./FAQs.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class FAQsService extends BaseService {
    static async fetchFAQs() {
        let FetchData = await Request.get(getFAQsApi);
        return FetchData;
    }

    static async addFAQ(data) {
        let addFaqs = await Request.post(addFAQApi, data)

        await this.resolveResponse({ ...addFaqs },
            "تم أضافة السؤال بنجاح",
            "حدث خطأ أثناء أضافة السؤال");

        return addFaqs;
    }

    static async FAQsCount() {
        let FetchCountData = await Request.get(FAQsCount);

        return FetchCountData;
    }

    static async editFAQ(data) {
        let url = editFAQsApi
        url += `${data.id}`
        let newdata = { question: data.question, answer: data.answer }
        let editFaqs = await Request.patch(url, newdata);
        await this.resolveResponse(editFaqs,
            "تم تعديل السؤال بنجاح",
            "حدث حطأ أثناء تعديل السؤال");
        return editFaqs;
    }
    static async deleteFAQs(id) {
        let url = editFAQsApi;
        url += `${id}`
        let deleteData = await Request.delete(url);
        await this.resolveResponse(deleteData,
            "تم حذف السؤال بنجاح",
            "حدث خطأ اثناء حذف السؤال");
        return deleteData;
    }

}