import state from "./mainFooter.state";
import getters from "./mainFooter.getters";
import mutations from "./mainFooter.mutationTypes";
import actions from "./mainFooter.actions";
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
