export default {
  /**
   * @param state
   * @param onlineCourses
   * @desc SELECT_COURSE_MODAL mutation
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  SELECT_COURSE_MODAL(state, courseModal) {
    state.courseModal = courseModal;
  },

  /**
   * @param state
   * @desc SET_ENROLLED mutation
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  SET_ENROLLED(state) {
    state.courseModal.enrolled = true;
  },

  /**
   * @param state
   * @desc SET_WISHED mutation
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  SET_WISHED(state) {
    state.courseModal.wished = true;
  }
};
