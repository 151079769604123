/**
 * @desc dashboard module apis routes
 * @author karam mustafa
 * @task #BACT-54
 * */

export default {
  path: '/dashboard',
  name: 'dashBoard',
  component: () => import('../DashBoard'),
  children: [
    {
      path: 'main',
      name: 'main',
      component: () => import('../Pages/Main/index'),
      meta: {
        requiresEditorAuth: true
      }
    },
    {
      path: 'Courses',
      name: 'courses',
      component: () => import('../Pages/Courses/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'categories',
      name: 'categories',
      component: () => import('../Pages/categories/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'users-course/:id',
      name: 'usersCourse',
      component: () => import('../Components/CourseInfo'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'course-form/:id',
      name: 'courseForm',
      component: () => import('../Pages/Courses/Components/dialog_courses'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'edit-course/:id',
      name: 'editCourse',
      component: () => import('../Pages/Courses/Pages/Modules/index'),
    },
    {
      path : 'edit-course-module/:id',
      name: 'editCourseModule',
      component: () => import('../Pages/Courses/Pages/Modules/index'),
      meta: {
        requiresEditorAuth: true
      }
    },
    {
      path : 'module-questions/:id',
      name: 'moduleQuestions',
      component: () => import('../Pages/Courses/Pages/Questions/index'),
      meta: {
        requiresEditorAuth: true
      }
    },
    {
      path : 'view-course/:id',
      name: 'viewCourse',
      component: () => import('../Components/CourseInfo'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'instructors',
      name: 'instructors',
      component: () => import('../Pages/Instructors/'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'students',
      name: 'students',
      component: () => import('../Pages/Students/Students.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'edit-student/:id',
      name: 'StudentEdit',
      component: () => import('../Pages/Students/components/StudentEdit/StudentEditTab.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'view-student/:id',
      name: 'viewStudents',
      component: () => import('../Pages/Students/components/StudentInfoTab.vue'),
      meta: {
        requiresAdminAuth: true
      },
    },
    {
      path: 'instructor/:id',
      component: () => import('../Pages/Instructors/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'instructor',
      component: () => import('../Pages/Instructors/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'new-instructor',
      name: 'newInstructor',
      component: () => import('../Pages/Instructors/Pages/InstructorInfo'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'edit-instructor/:id',
      name: 'editInstructor',
      component: () => import('../Pages/Instructors/Pages/InstructorInfo'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path : 'view-instructor/:id',
      name: 'viewInstructor',
      component: () => import('../Pages/Instructors/Pages/InstructorInfo'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'sliders',
      name: 'sliders',
      component: () => import('../Pages/Sliders/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'partners',
      name: 'partners',
      component: () => import('../Pages/Partners/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'certificates',
      name: 'certificates',
      component: () => import('../Pages/Certificates/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'faq',
      name: 'faq',
      component: () => import('../Pages/FAQs/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path:'Traning',
      name:'Training path',
      component: () => import('../Pages/TraningPath/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'edit-about-us',
      name: 'EditAboutUs',
      component: () => import('../Pages/About_Us/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'messages',
      name: 'messages',
      component: () => import('../Pages/Contact_Us/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'admins',
      name: 'admins',
      component: () => import('../Pages/Admins/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    },
    {
      path: 'editors',
      name: 'editors',
      component: () => import('../Pages/Admins/index.vue'),
      meta: {
        requiresAdminAuth: true
      }
    }
  ]
};
