import state from "./onlineCourse.state";
import getters from "./onlineCourse.getters";
import mutations from "./onlineCourse.mutationTypes";
import actions from "./onlineCourse.actions";
export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
