import InfoService from "../Services/info.service";
export default {
    fetchInstructorInfo({ commit }) {
        return new Promise((resolve, reject) => {
            InfoService.fetchInfo()
                .then((res) => {
                    console.log('from actions', res.data.response)
                    commit("SET_INSTRUCTOR_INFO", res.data.response.user);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    updateInstructorInfo({ commit }, data) {
        return new Promise((resolve, reject) => {
            InfoService.updateInfo(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
}
