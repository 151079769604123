import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Colors from './Mixins/GlobalColors';
import Icons from './Mixins/Icons';
import Auth from './Mixins/Auth';
import './Mixins';
import "./scss/index.css";
import './styles/index.scss';
import axios from 'axios';
import JsonExcel from 'vue-json-excel';

window.axios = axios;
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = 'https://bact-back.usol.ca/';
/**
 * @desc ukit package you can check out this package from here https://vuikit.js.org
 * @author karam mustafa
 * @task #BACT-38
 * */
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import '@vuikit/theme'

Vue.use(Vuikit);
Vue.use(VuikitIcons);
/**
 * @desc vue-scroll-to is package using to smooth scroll throwout components
 * @author karam mustafa
 * @task #BACT-36
 * */
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 400,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});


/**
 * @desc vue validate package , you can check out this package from here https://vuelidate.js.org
 * @author karam mustafa
 * @task #BACT-53
 * */
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

/**
 * @desc vue notification package , you can check out this package from here https://github.com/euvl/vue-notification/
 * @author karam mustafa
 * @task #BACT-101
 * */
import Notifications from 'vue-notification'

import i18n from './i18n'
Vue.use(Notifications);



/**
 * @desc prototype area ,  we will use any value we want to be public in all component
 * @author karam mustafa
 * @task #BACT-101
 * */
export const EventBus = new Vue();
Vue.config.productionTip = false;

Vue.component("downloadExcel", JsonExcel);  // added to export data tables to Excel files

new Vue({
 router,
 store,
 vuetify,
 i18n,
 render: h => h(App)
}).$mount('#app');
