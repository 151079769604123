import LearningPath from "../Pages/LearningPath/store/index";
import CoursesList from "../Pages/CoursesList/store/index";
import TrainingPathList from "../Pages/TrainingPathList/store/index";
import OnlineCourse from "../Pages/OnlineCourse/store/index";
import ModulesCourses from "../Pages/ModulesCourses/store/index";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    LearningPath,
    CoursesList,
    TrainingPathList,
    OnlineCourse,
    ModulesCourses
  }
};
