export default {
  /** @type {array} */
  modules: [],

  /** @type {string} */
  courseTitle: "",

  /** @type {string} */
  moduleTitle: "",

  /** @type {array} */
  moduleQA: [],

  /** @type {number} */
  userPercentage: 0,

  /**@type {array} */
  correctAnswers: [],

  /**@type {number} */
  correctAnswersCount: 0
};
