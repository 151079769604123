export default {
  /**
     * @desc check login status or actor if signed in
     * @author karam mustafa
     * @task #BACT-101
     * */
  getIsLogIn(state) {
    return state.isLogin;
  },
  /**
     * @desc get token value
     * @author karam mustafa
     * @task #BACT-38
     * */
  getToken(state) {
    return state.token;
  },
  /**
     * @desc get authentication user
     * @author karam mustafa
     * @task #BACT-38
     * */
  // getUser(state) {
  //   let defaultUser = {
  //     "id": '',
  //     "email": '',
  //     "name": '',
  //     "permissions": [],
  //     "roles": [],
  //   };
  //   if (Object.keys(this.state.user).length === 0) {
  //     let userFromStorage = localStorage.getItem('user');
  //     if (userFromStorage !== undefined && Object.keys(userFromStorage).length !== 0) this.state.userInfo = userFromStorage;
  //   }
  //   return Object.keys(this.state.user).length === 0 ? defaultUser : {...this.state.user};
  // }
}
