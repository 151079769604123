import state from './authAdmin.state';
import getters from './authAdmin.getters';
import mutations from './authAdmin.mutationTypes';
import actions from './authAdmin.actions';

export default {
  namespaced : true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
