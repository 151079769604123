import Request from "../../../core/Classes/Request";
import BaseService from "../../../core/Classes/BaseService";

export default class SearchService extends BaseService {
  /**
   * @param _
   * @desc fetchSearch function
   * @author Hamza Sweid
   * @task #BACT-223
   * */
  static fetchSearch(q) {
    return Request.get(`api/search?page=0&size=8&lang=ar&q=${q}`).then(
      (res) => {
        return res;
      }
    );
  }
}
