<script>
import { serialize } from "object-to-formdata";

export default {
  methods: {
    getBased(url) {
      return `${process.env.VUE_APP_BASE_URL}${url}`;
    },
    objectToFormData(object) {
      // more options in https://www.npmjs.com/package/object-to-formdata
      const options = {indices: true};
      const formData = serialize(
        object,
        options, // optional
      );
      return formData
    },
  },
};
</script>
