export const getCourses = "api/course/admin/all?page=0&size=1000"
export const addCourse = "api/course/admin"
export const updateCourse = "api/course/admin"
export const deleteCourse = "api/course/admin/"
export const getTraninPath="api/training-path/admin?page=0&size=1000"
export const getInstructions="api/instructor/admin?page=0&size=100"
export const getCategory="api/category"
export const updateOfflineApi = "api/course/offline/"
export const getOfflineDataApi = "api/course/admin/ofline-courses/"
export const deleteOfflineApi = "api/course/admin/offline/"
export const courseTranslationApi = "api/course/translate-admin"
export const offlineCourseTranslationApi = "api/course/offline-translate/" //:offline_id