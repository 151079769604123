export const getAllStudentsApi = "https://bact-back.usol.ca/api/user/admin/students?page=0&size=100";

export const getStudentCoursesApi = "https://bact-back.usol.ca/api/user/admin/";
export const getStudentCoursesApi2 = "/courses";

export const getStudentInstructorsApi = "https://bact-back.usol.ca/api/user/admin/";
export const getStudentInstructorsApi2 = "/instructors";

export const getStudentInfoApi = "https://bact-back.usol.ca/api/user/admin/";
export const deleteStudentApi = "https://bact-back.usol.ca/api/user/";

export const editStudentApi = "https://bact-back.usol.ca/api/user/admin/";
export const editStudentApi2 = "/edit-student";