import Request from "../../../../../core/Classes/Request";
import BaseService from "../../../../../core/Classes/BaseService";
import { addCourse, updateCourse, deleteCourse, getCourses, getCategory, getInstructions, getTraninPath, getOfflineDataApi, updateOfflineApi, deleteOfflineApi, courseTranslationApi, offlineCourseTranslationApi } from './courses.api'

export default class CoursesService extends BaseService {

    static async FetchCourses() {
        let FetchCourses = await Request.get(getCourses);
        console.log(FetchCourses)
        return FetchCourses;
    }

    static fetchOfflineData (courseId) {
        return Request.get(getOfflineDataApi + courseId).then((res) => {
          console.log(res)
          this.resolveResponse(res);
          return res;
        });
    }


    static async addCourse(data) {
        console.log(data)
        console.log(data.instructorId)
        const formdata = new FormData();
        formdata.append("instructorId",data.instructor_id);
        formdata.append("title",data.title);
        formdata.append("price",data.price);
        formdata.append("type",data.type);
        formdata.append("category_id", data.category_id);
        formdata.append("training_path_Ids",data.training_path_Ids);
        formdata.append("language",data.language);
        formdata.append("targets",data.targets);
        formdata.append("description",data.description);
        formdata.append("requirements",data.requirements);

        if (data.thumbnail)
        formdata.append("thumbnail",data.thumbnail);

        return Request.post(addCourse, formdata).then((res) => {
        if (res.response) {
            this.resolveResponse(
            res,
            "تمت إضافة الدورة بنجاح",
            JSON.stringify(res.response.data.message)
            );  
        }
        this.resolveResponse(
            res,
            "تمت إضافة الدورة بنجاح",
            "حدث خطأ أثناء الإضافة"
        );
        return res;
        })
    }

    static async addOfflineCourse(data) {
        console.log(data)
        console.log(data.language)
        if (data.ar_address) {
            data.ar_address = undefined;
        }
        if (data.ar_description) {
            data.ar_description = undefined;
        }
        return Request.post(updateOfflineApi, data).then((res) => {
        if (res.response) {
            this.resolveResponse(
            res,
            "تمت إضافة الدورة الحضورية بنجاح",
            JSON.stringify(res.response.data.message)
            );  
        }
        this.resolveResponse(
            res,
            "تمت إضافة الدورة الحضورية بنجاح",
            "حدث خطأ أثناء إضافة الدورة"
        );
        return res;
        })
    }

    static async updateCourse(data) {
        console.log(data)
        const formdata = new FormData();
        formdata.append("instructorId", data.instructor_id);
        formdata.append("title", data.title);
        formdata.append("price", data.price);
        formdata.append("type", data.type);
        formdata.append("course_id", data.course_id);
        formdata.append("category_id", data.category_id);
        formdata.append("training_path_Ids", data.training_path_Ids);
        formdata.append("targets",data.targets);
        formdata.append("description",data.description);
        formdata.append("requirements",data.requirements);
        // formdata.append("language", data.language);
        if (data.thumbnail)
        formdata.append("thumbnail", data.thumbnail);

        return Request.patch(updateCourse, formdata).then((res) => {
        if (res.response) {
            this.resolveResponse(
            res,
            "تم تعديل الدورة بنجاح",
            JSON.stringify(res.response.data.message)
            );  
        }
        this.resolveResponse(
            res,
            "تم تعديل الدورة بنجاح",
            "حدث خطأ أثناء تعديل الدورة"
        );
        return res;
        })
    }

    static async updateOfflineCourse(data) {
        
        // formdata.append("language", data.language);
        // formdata.append("courseId", data.courseId);
        // formdata.append("start_date", data.start_date);
        // formdata.append("end_date", data.end_date);
        // formdata.append("capacity", data.capacity);
        // formdata.append("description", data.description);
        // formdata.append("address", data.address);
        

        return Request.patch(updateOfflineApi + data.id, { courseId: data.courseId, start_date: data.start_date, end_date: data.end_date, capacity: data.capacity, description: data.description, address: data.address }).then((res) => {
        if (res.response) {
            this.resolveResponse(
            res,
            "تم تعديل الدورة الحضورية بنجاح",
            JSON.stringify(res.response.data.message)
            );  
        }
        this.resolveResponse(
            res,
            "تم تعديل الدورة الحضورية بنجاح",
            "حدث خطأ أثناء تعديل الدورة الحضورية"
        );
        return res;
        })
    }
    static async deleteCourse(id) {
        return Request.delete(deleteCourse + id).then((res) => {
            this.resolveResponse(res);
            return res;
          });
    }
    
    static deleteOfflineCourse (id) {
        return Request.delete(deleteOfflineApi + id).then((res) => {
          this.resolveResponse(res);
          return res;
        });
      }
    static async FetchCategory() {
        let Categories = await Request.get(getCategory);
        return Categories;
    }
    static async fetchInstructors() {
        let instructors = await Request.get(getInstructions);
        return instructors;
    }
    static async FetchTrainingPaths() {
        let TrainingPaths = await Request.get(getTraninPath)
        return TrainingPaths;
    }

    static async translateCourse(data) {

        // const formdata = new FormData();
        // formdata.append("course_id", data.course_id);
        // formdata.append("ar_title", data.ar_title);
        // formdata.append("ar_targets", data.ar_targets);
        // formdata.append("ar_description", data.ar_description);
        // formdata.append("ar_requirements", data.ar_requirements);
        // data.id = undefined;
        // let ar_data = {...data}

        // data.active = undefined;
        // data.category = undefined;
        // data.code = undefined;
        // data.courseInstructors = undefined;
        // data.default_lang = undefined;
        // data.description = undefined;
        // data.id = undefined;
        // data.offlineCourses = undefined;
        // data.price = undefined;
        // data.requirements = undefined;
        // data.status = undefined;
        // data.targets = undefined;
        // data.thumbnail = undefined;
        // data.title = undefined;
        // data.trainingPathCourses = undefined;
        // data.type = undefined;
        // data.ar_title = undefined;
        // data.ar_description = undefined;
        // data.ar_targets = undefined;
        // data.ar_requirements = undefined;
        // data.course_id = undefined;
        
        // data.course_id = ar_data.course_id
        // data.title = ar_data.ar_title;
        // data.description = ar_data.ar_description;
        // data.targets = ar_data.ar_targets;
        // data.requirements = ar_data.ar_requirements;
        
        
        // return Request.patch(courseTranslationApi, data).then((res) => {
        // if (res.response) {
        //     this.resolveResponse(
        //     res,
        //     " Course Translated Successfully",
        //     JSON.stringify(res.response.data.message)
        //     );  
        // }
        // this.resolveResponse(
        //     res,
        //     "Course Translated Successfully",
        //     "Course Translated Failed"
        // );
        // return res;
        // })
    }
    static async translateOfflineCourse(data) {

        // let ar_data = {...data}
        // ar_data.course_id = JSON.parse(JSON.stringify(data.id))
        // ar_data.description = JSON.parse(JSON.stringify(data.ar_description));
        // ar_data.address = JSON.parse(JSON.stringify(data.ar_address));
        // data.ar_address = undefined;
        // data.capacity = undefined;
        // data.default_lang = undefined;
        // data.ar_description = undefined;
        // data.end_date = undefined;
        // data.enrollable = undefined;
        // data.id = undefined;
        // data.start_date = undefined;
        // data.code = undefined;
        // data.course_id = ar_data.id;
        // data.address = ar_data.ar_address;
        // data.description = ar_data.ar_description;

        // return Request.patch(offlineCourseTranslationApi + ar_data.id, data).then((res) => {
        //     console.log(res)
        // if (res.response) {
        //     this.resolveResponse(
        //     res,
        //     " Course Translated Successfully",
        //     JSON.stringify(res.response.data.message)
        //     );  
        // }
        // this.resolveResponse(
        //     res,
        //     "Course Translated Successfully",
        //     "Course Translated Failed"
        // );
        // return res;
        // })
    }

}