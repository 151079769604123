<template>
  <div class="badge" @click="SwitchLang">{{ $t("lang") }}</div>
</template>

<script>
export default {
  name: "BaseBadge",

  methods: {
    SwitchLang() {
      this.$i18n.locale = this.$i18n.locale === "ar" ? "en" : "ar";
      localStorage.setItem("lang", this.$i18n.locale);
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.badge {
  width: 39px;
  height: 39px;
  background-color: var(--gray-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 3px var(--black-shadow);
  cursor: pointer;
}
</style>
