export default {
  /**
   * @param state
   * @param totalCoursesCount
   * @desc SET_TOTAL_COURESE_COUNT mutation
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  SET_TOTAL_COURESE_COUNT(state, totalCoursesCount) {
    state.totalCoursesCount = totalCoursesCount;
  },

  /**
   * @param state
   * @param trainingPathCourses
   * @desc SET_TRAINING_PATH_COURSES mutation
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  SET_TRAINING_PATH_COURSES(state, trainingPathCourses) {
    state.trainingPathCourses = trainingPathCourses;
  }
};
