import lecturesService from "../Services/lectures.service";
export default {
    fetchLectures({ commit }, id) {
        return new Promise((resolve, reject) => {
            lecturesService.fetchLectures(id)
                .then((res) => {
                    commit("SET_LECTURES", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchSingleLecture({ commit }, id) {
        return new Promise((resolve, reject) => {
            lecturesService.fetchSingleLecture(id)
                .then((res) => {
                    commit("SET_LECTURE", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    addNewLectureInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            lecturesService.addNewLecture(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    updateLectureInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            lecturesService.updateLecture(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    deleteLectureInstructor({ commit }, id) {
        console.log("from actions", id)
        return new Promise((resolve, reject) => {
            lecturesService.deleteLecture(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
}
