import Request from "../../../../../../../core/Classes/Request";
import { instructorStudentsApi } from "./Students.api";
import BaseService from "../../../../../../../core/Classes/BaseService";

export default class StudentsService extends BaseService {
   //        INSTRUCTOR Students SECTION

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructor's Students list 
   */
  static fetchStudents (id) {
    console.log(instructorStudentsApi + id)
    return Request.get(instructorStudentsApi + id + '/students?page=0&size=200').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's Students
   */
  static fetchStudentsWithSearch (data) { 
    let a = `/students?page=0&size=8&q=`
    console.log(instructorStudentsApi + data.id + a + data.searchTerm)
    return Request.get(instructorStudentsApi + data.id + a + data.searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}