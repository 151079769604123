export default {
  /** @type {array} */
  categories: [],

  /** @type {array} */
  trainingPaths: [],

  /** @type {array} */
  courses: []
};
