import CourseAreaService from "../../../Services/CourseArea.service";

export default {
  /**
   * @param commit
   * @param category_id
   * @desc getCoursesList function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  getCoursesList({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getCoursesList(id)
        .then((res) => {
          commit("SET_COURSES_LIST", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
