import ContactUsService from "../Services/contactUs.service";

export default {
  /**
   * @param msgData
   * @desc sendMsg function
   * @author Hamza Sweid
   * @task #BACT-180
   * */
  sendMsg({ _ }, data) {
    return new Promise((resolve, reject) => {
      ContactUsService.sendMsg(data)
        .then((res) => {
          let { data, status } = res;
          if (status < 300) {
            resolve(res);
          } else {
            console.log("fail");
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  }
};
