import state from './Modules.state'
import getters from './Modules.getters'
import mutations from './Modules.mutationTypes'
import actions from './Modules.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}