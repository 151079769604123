const state = {
  /** @type {object} */
  userInfos: {},

  /** @type {Array} */
  allWishes: [],

  /** @type {Array} */
  studentCourses: [],

  /** @type {array} */
  bills: []
};

export default state;
