import CertificatesService from "../Services/certificates.service";

export default {
  
  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc get the contact messages 
   */
  fetchCertificates ({ commit }) {
    return new Promise((resolve, reject) => {
      CertificatesService.fetchCertificates()
        .then((response) => {
          console.log(response.data.response)
          commit("SET_CERTIFICATES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCertificatesCount({ commit }) {
    return new Promise((resolve, reject) => {
      CertificatesService.fetchCertificatesCount()
        .then((response) => {
          commit("SET_CERTIFICATES_COUNT", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
}