export default {
  /**
   * @param state
   * @param sliders
   * @desc SET_SLIDERS mutation
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  SET_SLIDRES(state, sliders) {
    state.sliders = sliders;
  },

  /**
   * @param state
   * @param onlineCourses
   * @desc SET_ONLINE_COURSES mutation
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  SET_ONLINE_COURSES(state, onlineCourses) {
    state.onlineCourses = onlineCourses;
  },

  /**
   * @param state
   * @param offlineCourses
   * @desc SET_OFFLINE_COURSES mutation
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  SET_OFFLINE_COURSES(state, offlineCourses) {
    state.offlineCourses = offlineCourses;
  },

  /**
   * @param state
   * @param sliders
   * @desc SET_COURSE_CATEGORIES mutation
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  SET_COURSE_CATEGORIES(state, courseCategories) {
    state.courseCategories = courseCategories;
  },

  /**
   * @param state
   * @param ourPartners
   * @desc SET_OUR_PARTNERS mutation
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  SET_OUR_PARTNERS(state, partners) {
    state.partners = partners;
  }
};
