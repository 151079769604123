import Request from "../../../core/Classes/Request";
import {
  slidersApi,
  onlineCorsesApi,
  offlineCorsesApi,
  courseCategoriesApi,
  ourPartnerApi
} from "./home.api";
import BaseService from "../../../core/Classes/BaseService";

export default class HomeService extends BaseService {
  /**
   * @param _
   * @desc getSliders function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  static getSliders() {
    return Request.get(slidersApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getOnlineCourses function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  static getOnlineCourses() {
    return Request.get(onlineCorsesApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getOfflineCourses function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  static getOfflineCourses() {
    return Request.get(offlineCorsesApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getCourseCategories function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  static fetchCourseCategories() {
    return Request.get(courseCategoriesApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getOurPartners function
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  static getOurPartners() {
    return Request.get(ourPartnerApi).then((res) => {
      return res;
    });
  }
}
