import ProfileService from "../Services/profile.service";

const actions = {
  /**
   * @param commit
   * @desc getUserInfos function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  getUserInfos({ commit }) {
    return new Promise((resolve, reject) => {
      ProfileService.getUserInfos()
        .then((res) => {
          commit("SET_USER_INFOS", res.data.response.user);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @desc editUserInfos function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  editUserInfos({ _ }, userInfos) {
    return new Promise((resolve, reject) => {
      ProfileService.editUserInfos(userInfos)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @desc changePassword function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  changePassword({ _ }, data) {
    return new Promise((resolve, reject) => {
      ProfileService.changePassword(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @desc getAllWishes function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  getAllWishes({ commit }) {
    return new Promise((resolve, reject) => {
      ProfileService.getAllWishes()
        .then((res) => {
          commit("SET_ALL_WISHES", res.data.response);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @param id
   * @desc removeWish function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  removeWish({ commit }, id) {
    return new Promise((resolve, reject) => {
      ProfileService.removeWish(id)
        .then((res) => {
          commit("REMOVE_WISH", id);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @desc getStudentCourses function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  getStudentCourses({ commit }) {
    return new Promise((resolve, reject) => {
      ProfileService.getStudentCourses()
        .then((res) => {
          commit("SET_STUDENT_COURSES", res.data.response);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @desc getBills function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  getBills({ commit }) {
    return new Promise((resolve, reject) => {
      ProfileService.getBills()
        .then((res) => {
          commit("SET_BILLS", res.data.response);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @param commit
   * @param billId
   * @desc payBill function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  payBill({ _ }, id) {
    return new Promise((resolve, reject) => {
      ProfileService.payBill(id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default actions;
