import Request from "../../../../../core/Classes/Request";
import { PartnersApi } from "./partners.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class PartnersService extends BaseService {
  
  /**
   * @desc fetch Partners
   * @author Ibrahim Zanbily
   * @task #BACT-189-test2
   * */
  static fetchPartners() {
    return Request.get(PartnersApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
  /**
   * @desc add a Partner
   * @author Ibrahim Zanbily
   * @task #BACT-189-test2
   * */
  static addPartner (data) {
    const formdata=new FormData();
    formdata.append("name",data.name);
    // formdata.append("ar_description",data.description);
    formdata.append("description",data.description);
    if(data.avatar)
    formdata.append("avatar",data.avatar);
    return Request.post(PartnersApi, formdata).then((res) => {
      this.resolveResponse(
        res,
        "تمت إضافة الشريك بنجاح",
        "حدث خطأ أثناء إضافة الشريك"
      );
      return res;
    });
  }
  /**
   * @desc update a Partner
   * @author Ibrahim Zanbily
   * @task #BACT-189-test2
   * */
  static updatePartner(data) {
    console.log(typeof data)
    console.log(PartnersApi + data.id)
    const formdata=new FormData();
    formdata.append("name",data.name);
    formdata.append("description",data.description);
    formdata.append("ar_description",data.description);
    if(data.avatar)
    formdata.append("avatar",data.avatar);
    return Request.update(PartnersApi + data.id, formdata).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
  /**
   * @desc delete a Partner
   * @author Ibrahim Zanbily
   * @task #BACT-189-test2
   * */
  static deletePartner(id) {
    return Request.delete(PartnersApi + id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

}