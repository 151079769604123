export default {
  getCounters(state) {
    return state.counters;
  },
  getPendingCourses(state) {
    console.log(state.pendingCourses)
    return state.pendingCourses;
  },
  loadingPendingCourses (state) {
    return state.loadingPendingCourses;
  },
  getPendingCoursesCount (state) {
    return state.pendingCoursesCount;
  },
  getTopCourses (state) {
    return state.topCourses;
  },
  getEnrollmentPeroformance (state) {
    return state.enrollmentPeroformance;
  },
  getLiveCourses (state) {
    return state.liveCourses;
  },
  getDeniedCourses (state) {
    return state.deniedCourses;
  },
}