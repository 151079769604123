import state from "./coursesList.state";
import getters from "./coursesList.getters";
import mutations from "./coursesList.mutationTypes";
import actions from "./coursesList.actions";
export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
