import Request from "../../../../../core/Classes/Request";
import { certificatesApi, certificatesCountApi } from "./certificates.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class CertificatesService extends BaseService {

  static fetchCertificates () {
    return Request.get(certificatesApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static fetchCertificatesCount() {
    return Request.get(certificatesCountApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}