import FAQsService from '../Services/FAQs.services'
export default {

    async fetchFAQs({ commit }) {
        commit("SET_LOADING",true)
        try {
            
            let response = await FAQsService.fetchFAQs();
            await commit("SET_FAQS", response.data.response);
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async addFAQ({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await FAQsService.addFAQ(data);
            await dispatch('fetchFAQs');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async editFAQ({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await FAQsService.editFAQ(data);
            await dispatch('fetchFAQs');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async delete({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await FAQsService.deleteFAQs(data);
            await dispatch('fetchFAQs');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)
    },

    async FAQsCount({ commit }) {
        try {
            let response = await FAQsService.FAQsCount();
            await commit("SET_FAQS_COUNT", response.data.response);

        } catch (error) {
            console.log(error);
        }

    }



}