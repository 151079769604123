import state from './courses.state';
import actions from '../../Course/Store/courses.action';
import mutations from './courses.mutationTypes';
import getters from './courses.getters';
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author Obada Saleh
 * @task #BACT-73
 * */
export default{
  state,
  actions,
  mutations,
  getters,
  modules:{}
}
