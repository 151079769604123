import Request from "@/core/Classes/Request";
import { getInstructorInfo, updatePass } from "./info.api";
import BaseService from "@/core/Classes/BaseService";
export default class InfoService extends BaseService {
    /**
 * @desc fetchCategories function
 * @author Obada Saleh
 * @task #BACT-189
 * */
    static fetchInfo() {
        return Request.get(getInstructorInfo).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static changePassword(data) {
        console.log("from services", data)
        return Request.patch(updatePass, data).then((res) => {
            this.resolveResponse(res,
                "تم تعديل الوحدة",
                " حدث خطأ أثناء تعديل الوحدة"
            );
            return res
        });
    }
    static updateInfo(data) {
        console.log("from services", data)
        return Request.patch(getInstructorInfo, data).then((res) => {
            this.resolveResponse(res,
                "تم تعديل الوحدة",
                " حدث خطأ أثناء تعديل الوحدة"
            );
            return res
        });
    }
}