export default {
  SET_STUDENTS(state, data) {
    state.students = data;
  },
  SET_STUDENT_COURSES(state, courses) {
    state.courses = courses;
  },
  SET_STUDENT_INSTRUCTORS(state, instructors) {
    state.instructors = instructors;
  },
  SET_STUDENT(state, student) {
    state.student = student;
  },
  SET_LOADING_STATUS (state, status) {
    state.loadingStudents = status
  },
  SET_LOADING_STATUS_INSTRUCTORS(state, status) {
    state.loadingStudentInstructors = status
  },
  SET_LOADING_STATUS_COURSES (state, status) {
    state.loadingStudentCourses = status
  }
};
