import CoursesService from '../Services/courses.services';
export default {

    async FetchCourses({ commit }) {
        commit("SET_ONLINE_LOADING", true);
        try {
            let response = await CoursesService.FetchCourses();
            await commit("SET_ONLINE_COURSES", response.data.response);

        } catch (error) {
            console.log(error);
        }
        commit("SET_ONLINE_LOADING", false);
    },

    fetchOfflineData({ commit }, courseId) {
        commit("SET_ONLINE_LOADING", true)
        return new Promise((resolve, reject) => {
            CoursesService.fetchOfflineData(courseId)
            .then((response) => {
              console.log(response.data.response)
              commit("SET_OFFLINE_DATA", response.data.response);
              resolve(response);
              commit("SET_ONLINE_LOADING", false)
            })
            .catch((err) => {
              reject(err);
            });
        });
      },

    async FetchTrainingPaths({ commit }) {
        try {
            let response = await CoursesService.FetchTrainingPaths();
            await commit("SET_TRAINING_PATHS", response.data.response);
        } catch (error) {
            console.log(error);
        }

    },
    async fetchInstructors({ commit }) {
        try {
            let response = await CoursesService.fetchInstructors();
            await commit("SET_INSTRUCTOR", response.data.response);
        } catch (error) {
            console.log(error);
        }
    },
    async FetchCategory({ commit }) {
        try {
            let response = await CoursesService.FetchCategory();
            await commit("SET_CATEGORY", response.data.response);
        } catch (error) {
            console.log(error);
        }
    },

    async addCourse({ commit, dispatch }, data) {
        console.log(data.instructor_id)
        commit("SET_ONLINE_LOADING", true);
        return new Promise((resolve, reject) => {
            CoursesService.addCourse(data)
              .then((res) => {
                dispatch('FetchCourses');
                resolve(res);
                commit("SET_ONLINE_LOADING", false);
              })
              .catch((err) => reject(err));
          });
    },
    async addOfflineCourse({ commit }, data) {
        commit("SET_ONLINE_LOADING", true);
        return new Promise((resolve, reject) => {
            CoursesService.addOfflineCourse(data)
              .then((res) => {
                resolve(res);
                commit("SET_ONLINE_LOADING", false);
              })
              .catch((err) => reject(err));
          });
    },

    async updateCourse({ commit, dispatch }, data) {
        commit("SET_ONLINE_LOADING", true);
        return new Promise((resolve, reject) => {
            CoursesService.updateCourse(data)
              .then((res) => {
                dispatch('FetchCourses');
                resolve(res);
                commit("SET_ONLINE_LOADING", false);
              })
              .catch((err) => reject(err));
          });
    },

    async updateOfflineCourse({ commit, dispatch }, data) {
        commit("SET_ONLINE_LOADING", true);
        return new Promise((resolve, reject) => {
            CoursesService.updateOfflineCourse(data)
              .then((res) => {
                dispatch('FetchCourses');
                resolve(res);
                commit("SET_ONLINE_LOADING", false);
              })
              .catch((err) => reject(err));
          });
    },
    async translateCourse({ commit }, data) {
        return new Promise((resolve, reject) => {
            CoursesService.translateCourse(data)
              .then((res) => {
                // dispatch('FetchCourses');
                resolve(res);
              })
              .catch((err) => reject(err));
          });
    },
    async translateOfflineCourse({ commit }, data) {
        return new Promise((resolve, reject) => {
            CoursesService.translateOfflineCourse(data)
              .then((res) => {
                // dispatch('FetchCourses');
                resolve(res);
              })
              .catch((err) => reject(err));
          });
    },
    
    async deleteCourse({ commit, dispatch }, data) {
        commit("SET_ONLINE_LOADING", true);
        try {
            await CoursesService.deleteCourse(data.id);
            await dispatch("FetchCourses");
        } catch (error) {
            console.log(error);
        }
        commit("SET_ONLINE_LOADING", false);
    },
    
    deleteOfflineCourse ({ _ }, id) {
        return new Promise((resolve, reject) => {
            CoursesService.deleteOfflineCourse(id)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
    },
}