export default {

    SET_ONLINE_COURSES(state, data) {
        state.onlineCourses = data;
    },

    SET_ONLINE_LOADING(state, data) {
        state.loadingCoursesOnline = data
    },
    SET_CATEGORY(state, data) {
        state.category = data;
    },
    SET_TRAINING_PATHS(state, data) {
        state.trainingPaths = data;
    },
    SET_INSTRUCTOR(state, data) {
        state.instructor = data;
    },

    SET_OFFLINE_DATA (state, data) {
        state.offlineData = data;
    }



}