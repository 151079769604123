import Request from "@/core/Classes/Request";
import { addNewCourse, deleteCourse, getCourses, offline, statusApi, getOfflineCoursesApi } from "./courses.api";
import BaseService from "@/core/Classes/BaseService";
export default class coursesService extends BaseService {
    /**
 * @desc fetchCategories function
 * @author Obada Saleh
 * @task #BACT-189
 * */
    static fetchCourses(query) {
        return Request.get(getCourses, query).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }

    static fetchOfflineCourses() {
        return Request.get(getOfflineCoursesApi).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }

    static updateCourse(data) {
        console.log("from services", data)
        return Request.patch(addNewCourse, data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم تعديل الدورة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم تعديل الدورة",
                " حدث خطأ أثناء تعديل الدورة"
            );
            return res
        });
    }
    static deleteCourse(id) {
        return Request.delete(`${deleteCourse}/${id}`).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم حذف الدورة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم حذف الدورة",
                " حدث خطأ أثناء حذف الدورة"
            );
            return res
        });
    }
    static fetchSingleCourses(id) {
        return Request.get(`${getCourses}/${id}`).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static addNewCourse(data) {
        console.log("from services", data)
        return Request.post(addNewCourse, data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم إضافة الدورة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم إضافة الدورة",
                " حدث خطأ أثناء إضافة الدورة"
            );
            return res
        });
    }
    static addOfflineInfo(data) {
        console.log("from services", data)
        return Request.post(offline, data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم ",
                " حدث خطأ"
            );
            return res
        });
    }
    static updateOfflineInfo(payload) {
        console.log("from services", payload)
        payload.data.id = undefined;
        payload.data.code = undefined;
        payload.data.default_lang = undefined;
        payload.data.language = undefined;
        return Request.patch(offline + '/' + payload.id, payload.data).then((res) => {
            
            return res
        });
    }

    static resubmitCourse(id) {
        console.log('service',id)
        return Request.patch(statusApi + id + '/status').then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تمت إعادة إرسال الدورة بنجاح ",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تمت إعادة إرسال الدورة بنجاح ",
                " حدث خطأ أثناء إعادة إرسال الدورة"
            );
            return res
        });
    }
}