import axios from "axios";
import store from "../../store/index";
import router from '../../router/index';

function goToLogin() {
  let instructor = router.history.current.fullPath.indexOf("instructor") !== -1;
  let dashboard = router.history.current.fullPath.indexOf("dashboard") !== -1;
  let role = localStorage.getItem("adminType");
  console.log(instructor, dashboard, role)
  if(instructor) {
      router.push('/instructor-dashboard/login')
  }
  else if(dashboard) {
    if(role === 'editor')
      router.push('/dashboard/editorLogin');
    else 
    router.push('/dashboard/adminLogin');
  }
  else {
    router.push('/login');

  }

}
// show loader in each request
axios.interceptors.request.use(
  (confiq) => {
    store.state.isLoading = true;
    return confiq;
  },
  (erorr) => {
    store.state.isLoading = false;
    return erorr;
  }
);
axios.interceptors.response.use(
  (response) => {
    store.state.isLoading = false;
    return response;
  },
  (error) => {
    store.state.isLoading = false;
    if(error.response.data.status === 'Unauthenticated') 
    {
      console.log(error.response.data.status === 'Unauthenticated')
      goToLogin();
    }
    return error;
  }
);
export default class Request {
  static async get(url, queryParams) {
    let headers = this.getHeaders();
    return await axios
      .get(url, { params: queryParams, headers: headers })
      .then((res) => {
        return res;
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  }

  static async patch(url, data) {
    let headers = this.getHeaders();
    return await axios
      .patch(url, data, { headers: headers })
      .then((res) => {
        return res;
      })
      .catch(function(error) {
        return error;
      });
  }
  static async post(url, data) {
    let headers = this.getHeaders();
    return await axios
      .post(url, data, { headers: headers })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }
  static async delete(url) {
    let headers = this.getHeaders();
    return await axios
      .delete(url, { headers: headers })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getUrlencodedHeaders() {
    const token = localStorage.getItem('type') ? localStorage.getItem('token') : localStorage.getItem('student_token')
    return {
      accept: "*/*",
      "content-type": "application/x-www-form-urlencoded",
      authorization: "Bearer " + token
    };
  }
  static async update(url, data) {
    let headers = this.getHeaders();
    return await axios
      .patch(url, data, { headers: headers })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getHeaders() {
    const token = (localStorage.getItem('type')==='instructor' || localStorage.getItem('type')==='admin') ? localStorage.getItem('token') : localStorage.getItem('student_token')
    return {
      accept: "application/json",
      "content-type": "application/json",
      authorization: "Bearer " + token
    };
  }
}
