import state from "./contactUs.state";
import getters from "./contactUs.getters";
import mutations from "./contactUs.mutationTypes";
import actions from "./contactUs.actions";
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
