import Vue from "vue";
import Vuex from "vuex";
import CourseInfoModal from "../core/CourseInfoModal/Store/index";
import Home from "../modules/Home/Store/index";
import AboutUs from "../core/AboutUs/Store/index";
import Search from "../core/Search/Store/index";
import Auth from "../modules/Auth/Store/index";
import AuthAdmin from "../modules/AuthAdmin/Store/index";
import ContactUs from "../core/ContactUs/Store/index";
import MainFooter from "../core/Footer/MainFooter/Store/index";
import CourseArea from "../modules/CourseArea/Store/index";
import Profile from "../modules/Profile/Store/index";
import DashBoard from "../modules/DashBoard/Store/index";
import FAQs from '../modules/DashBoard/Pages/FAQs/Store/index';
import Traning from '../modules/DashBoard/Pages/TraningPath/Store/index';
import ContactUsAdmin from '../modules/DashBoard/Pages/Contact_Us/Store/index';
import AboutUsAdmin from '../modules/DashBoard/Pages/About_Us/Store/index';
import Instructors from '../modules/DashBoard/Pages/Instructors/Store/index';
import FAQsPage from "../modules/FAQs/store/index"
import DashboardMain from "../modules/DashBoard/Pages/Main/Store/index"
import InstructorDashBoard from "../modules/InstructorDashBoard/Store"
import InstructorCourses from "../modules/InstructorDashBoard/Pages/Course/Store"
import InstructorModules from "../modules/InstructorDashBoard/Pages/Module/Store"
import InstructorLectures from "../modules/InstructorDashBoard/Pages/Lecture/Store"
import InstructorInfo from "../modules/InstructorDashBoard/Pages/Info/Store"
import Courses from "../modules/DashBoard/Pages/Courses/Store/index"
import Partners from '../modules/DashBoard/Pages/Partners/Store/index'
import Certificates from '../modules/DashBoard/Pages/Certificates/Store/index'
import InstructorQuestions from '../modules/InstructorDashBoard/Pages/Module/Pages/Questions/Store/index'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rtl: localStorage.getItem("lang") === "en" ? false : true,
    isLoading: false,
    isAdmin: (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal') ? true : false,
    errorMessage: ''
  },
  mutations: {},
  actions: {},
  modules: {
    InstructorCourses,
    InstructorModules,
    InstructorLectures,
    CourseInfoModal,
    Home,
    AboutUs,
    Search,
    Auth,
    AuthAdmin,
    ContactUs,
    MainFooter,
    Profile,
    CourseArea,
    DashBoard,
    FAQsPage,
    FAQs,
    Traning,
    ContactUsAdmin,
    AboutUsAdmin,
    DashboardMain,
    Instructors,
    Courses,
    Partners,
    InstructorDashBoard,
    Certificates,
    InstructorInfo,
    InstructorQuestions
  }
});
