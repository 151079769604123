export default {
    /**
       * @desc get categories
       * @author Obada Saleh
       * @task #BACT-73
       * */
    getCourses(state) {
        return state.coursesArray;
    },
    getOfflineCourses (state) {
        return state.offlineCourses;
    },
    getPendingCourses (state) {
        return state.coursesArray.filter(course => {
            course.status !== 'live'
        })
    },
    getCourse(state) {
        return state.course
    },
}
