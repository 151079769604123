import Request from "../../../../../core/Classes/Request";
import { editStudentApi, editStudentApi2, getAllStudentsApi, getStudentCoursesApi , getStudentCoursesApi2 , getStudentInstructorsApi , getStudentInstructorsApi2 , getStudentInfoApi } from "./Students.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class StudentsService extends BaseService {
    static getAllStudents() {
        return Request.get(getAllStudentsApi).then((res) => {
          this.resolveResponse(res , "تم عرض الطلاب بنجاح" , "حدث خطأ أثناء عرض الطالب");
          return res;
        });
      }
    
      static getStudentCourses(id) {
        return Request.get(getStudentCoursesApi + id + getStudentCoursesApi2).then(
          (res) => {
            this.resolveResponse(res , "تم عرض كورسات الطالب بنجاح" , "حدث خطأ أثناء عرض كورسات الطالب");
            return res;
          }
        );
      }
    
      static getStudentInstructors(id) {
        return Request.get(
          getStudentInstructorsApi + id + getStudentInstructorsApi2
        ).then((res) => {
          this.resolveResponse(res ,  "تم عرض اساتذة الطالب بنجاح" , "حدث خطأ أثناء عرض اساتذة الطالب");
          return res;
        });
      }
    
      static getStudentInfo(id) {
        return Request.get(getStudentInfoApi + id).then((res) => {
          this.resolveResponse(res ,  "تم عرض معلومات الطالب بنجاح" , "حدث خطأ أثناء عرض معلومات الطالب");
          return res;
        });
      }
    
      static editStudentPage(data) {
        console.log(editStudentApi + data.id + editStudentApi2);
        const formdata = new FormData();
        formdata.append("firstName", data.firstName);
        formdata.append("lastName", data.lastName);
        formdata.append("phone", data.phone);
        formdata.append("email", data.email);
        formdata.append("country", data.country);
        formdata.append("identityNumber", data.identityNumber);
        formdata.append("birthDate", data.birthDate);
        if (data.avatar) formdata.append("avatar", data.avatar);
        console.log(formdata);
        console.log(data);
        return Request.patch(
          editStudentApi + data.id + editStudentApi2,
          formdata
        ).then((res) => {
          this.resolveResponse(res,  "تم التعديل بنجاح" , "حدث خطأ أثناء التعديل ");
          console.log(formdata);
          console.log(res);
          return res;
        });
      }

}