export default {
  /**
   * @param state
   * @param trainingPaths
   * @desc SET_TRAINING_PATHS mutation
   * @author Hamza Sweid
   * @task #BACT-191
   * */
  SET_TRAINING_PATHS(state, trainingPaths) {
    state.trainingPaths = trainingPaths;
  }
};
