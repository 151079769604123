import state from  './dashboard.state';
import actions from  './dashboard.actions';
import mutations from  './dashboard.mutationTypes';
import getters from  './dashboard.getters';
import Category from '../Pages/categories/Store'
import Partners from "../Pages/Partners/Store/index.js"
import Sliders from "../Pages/Sliders/Store/index.js"
import Students from "../Pages/Students/Store/index.js"

import Admins from "../Pages/Admins/Store/index.js";
import FAQs from "../Pages/FAQs/Store/index";
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author karam mustafa
 * @task #BACT-73
 * */
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    Category,
    Partners,
    Sliders,
    Admins,
    FAQs,
    Students
  }
};
