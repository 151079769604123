import PartnersService from "../Services/partners.service";

export default {
  
  fetchPartners({ commit }) {
    console.log('fetch')
    return new Promise((resolve, reject) => {
      PartnersService.fetchPartners()
        .then((response) => {
          commit("SET_PARTNERS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPartner ({ _, dispatch }, data) {
    return new Promise((resolve, reject) => {
      PartnersService.addPartner(data)
        .then((res) => {
          dispatch('fetchPartners');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  updatePartner({ _, dispatch }, data ) {
    return new Promise((resolve, reject) => {
      PartnersService.updatePartner(data)
        .then((response) => {
          dispatch('fetchPartners');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePartner ({ _, dispatch }, id) {
    return new Promise((resolve, reject) => {
      PartnersService.deletePartner(id)
        .then((response) => {
          dispatch('fetchPartners');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}