import AboutUsService from "../Services/aboutUs.service";

export default {
  /**
   * @param commit
   * @desc getImgAndMsg function
   * @author Hamza Sweid
   * @task #BACT-179
   * */
  getImgAndMsg({ commit }) {
    return new Promise((resolve, reject) => {
      AboutUsService.getImgAndMsg()
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            commit("GET_IMG_AND_MSG", res.data.response);
            resolve(res);
          } else {
            console.log("fail");
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getAccounts function
   * @author Hamza Sweid
   * @task #BACT-179
   * */
  getAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      AboutUsService.getAccounts()
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            commit("GET_ACCOUNTS", res.data.response);
            resolve(res);
          } else {
            console.log("fail");
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  }
};
