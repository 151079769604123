export default {
  /** @type {boolean} */
  isLogin: localStorage.getItem("student_loginStatus")
    ? JSON.parse(localStorage.getItem("student_loginStatus"))
    : false,
  /** @type {object} */
  user: {},
  /** @type {string | null | undefined} */
  student_token: localStorage.getItem("student_token"),
  /** @type {string | null | undefined} */
  student_refresh_token: localStorage.getItem("student_refresh_token")
};
