export default {
  /** @type {object} */
  onlineCourse: {},

  /** @type {object} */
  courseIndex: [],

  /** @type {object} */
  lecture: {},

  /** @type {string} */
  attendenceRate: "",

  /** @type {number} */
  currentLecture: 1,

  /** @type {number} */
  lecturesCount: 0
};
