export default {
    /**
     * @params {mixed} state
     * @params mixed userCredential
     * @desc logout function
     * @author karam mustafa
     * @task #BACT-101
     * */
    LOGIN(state, data) {
        localStorage.setItem("student_token", data.token);
        localStorage.setItem('student_loginStatus', true);
        state.student_token = data.token;
        state.isLogin = true;
        localStorage.setItem("student_refresh_token", data.refresh_token);
        state.student_refresh_token = data.refresh_token;
    },
    /**
     * @params {mixed} state
     * @params mixed userCredential
     * @desc register function
     * @author karam mustafa
     * @task #BACT-101
     * */
    REGISTER(state, data) {
        if (data.type == 'user') {
            localStorage.setItem("student_token", data.token);
            localStorage.setItem("student_loginStatus", true);
            state.student_token = data.token;
            state.isLogin = true;
            localStorage.setItem("student_refresh_token", data.refresh_token);
            state.student_refresh_token = data.refresh_token;
        }
    }
  };
