import Request from "../../../core/Classes/Request";
import { getImgAndMsg, getAccounts } from "./aboutUs.api";
import BaseService from "../../../core/Classes/BaseService";

export default class AboutUsService extends BaseService {
  /**
   * @param _
   * @desc getImgAndMsg function
   * @author Hamza Sweid
   * @task #BACT-179
   * */
  static getImgAndMsg() {
    return Request.get(getImgAndMsg).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getAccounts function
   * @author Hamza Sweid
   * @task #BACT-179
   * */
    static getAccounts() {
      return Request.get(getAccounts).then((res) => {
        return res;
      });
    }
}
