import state from './lectures.state';
import actions from './lectures.action';
import mutations from './lectures.mutationTypes';
import getters from './lectures.getters';
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author karam mustafa
 * @task #BACT-73
 * */
export default{
  state,
  actions,
  mutations,
  getters,
  modules:{}
}
