import MainFooterService from "../Services/mainFooter.service";

export default {
  /**
   * @param commit
   * @desc getImgAndMsg function
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  getImgAndMsg({ commit }) {
    return new Promise((resolve, reject) => {
      MainFooterService.getImgAndMsg()
        .then((res) => {
          commit("GET_IMG_AND_MSG", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getAccounts function
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  getAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      MainFooterService.getAccounts()
        .then((res) => {
          commit("GET_ACCOUNTS", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
