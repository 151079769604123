export default {
    /**
       * @desc get categories
       * @author Obada Saleh
       * @task #BACT-73
       * */
    getLectures(state) {
        return state.lectures.lectures
    },
    getLecture(state) {
        return state.lecture
    }
}
