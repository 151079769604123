
export default {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_LOADING(state, isloading) {
    state.loading = isloading;
  },
  SET_IMAGE(state, image) {
    state.imageSorce = image;
  }

}