import Request from "../../../../../core/Classes/Request";
import {
  editSliderAPI,
  addSliderAPI,
  getSlidersAPI,
  deleteSliderAPI,
} from "./Sliders.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class SliderService extends BaseService {
  static getSliders() {
    return Request.get(getSlidersAPI).then((res) => {
      this.resolveResponse(res, "تم عرض العروض بنجاح");
      return res;
    });
  }

  static addSlider(data) {
    console.log(addSliderAPI);
    let formdata = new FormData();
    formdata.append("order", data.order);
    formdata.append("image_url", data.image_url);
    console.log(formdata);
    return Request.post(addSliderAPI, formdata).then((res) => {
      this.resolveResponse(
        res,
        "تمت إضافة العرض بنجاح",
        "حدث خطأ أثناء إضافة العرض"
      );
      return res;
    });
  }

  static editSlider(data) {
    console.log(editSliderAPI + data.id);
    let formdata = new FormData();
    formdata.append("order", data.order);
    formdata.append("image_url", data.image_url);
    console.log(formdata);
    return Request.patch(editSliderAPI + data.id, formdata).then((res) => {
      this.resolveResponse(
        res,
        "تم تعديل العرض بنجاح",
        "حدث خطأ أثناء تعديل العرض"
      );
      return res;
    });
  }

  static deleteSlider(id) {
    return Request.delete(deleteSliderAPI + id).then((res) => {
      this.resolveResponse(
        res,
        "تم حذف العرض بنجاح",
        "حدث خطأ أثناء حذف العرض"
      );
      return res;
    });
  }
}
