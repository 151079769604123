import AuthService from "../Services/auth.service";

export default {
    /**
     * @param mixed commit
     * @param data
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * */
    login({commit}, data) {
        return new Promise((resolve, reject) => {
            AuthService.login(data).then(res => {
                let {data, status} = res;
                if (status < 300) {
                    commit('LOGIN', res.data.response);
                    resolve(res);
                } else {
                    reject(res);
                }
            }).catch(err => reject(err));
        })
    },
    /**
     * @param mixed commit
     * @param data
     * @desc register function
     * @author karam mustafa
     * @task #BACT-101
     * */
    register({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService.register(data).then(res => {
                let {data, status} = res;
                if (status < 300) {
                    commit("REGISTER", res.data.response);
                    resolve(res);
                } else {
                    reject(res);
                }
            }).catch(err => {
                reject(err)
            });
        })
    },
    /**
     * @params mixed commit
     * @desc logout function
     * @author karam mustafa
     * @task #BACT-101
     * */
    logout({commit}) {
        commit('LOGOUT');
    },
    /**
     * @params mixed commit
     * @desc forget password  function
     * @author karam mustafa
     * @task #BACT-101
     * */
    forgetPassword(_ , data) {
        return new Promise((resolve, reject) => {
            AuthService.forgetPassword(data).then(res => resolve(res)).catch(err => reject(err));
        })
    },
    /**
     * @params mixed commit
     * @desc forget password  function
     * @author karam mustafa
     * @task #BACT-101
     * */
    resetPassword(_ , data) {
        return new Promise((resolve, reject) => {
            AuthService.resetPassword(data).then(res => resolve(res)).catch(err => reject(err));
        })
    },
}
