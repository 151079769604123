import SearchService from "../Services/search.service";

export default {
  /**
   * @param commit
   * @desc fetchSearch function
   * @author Hamza Sweid
   * @task #BACT-223
   * */
  fetchSearch({ commit }, q) {
    return new Promise((resolve, reject) => {
      SearchService.fetchSearch(q)
        .then((res) => {
          commit("SET_SEARCH", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
