/*
 * BACT-50
 * Author: Ali Ataf
 * Date: 20-10-2020
 */
import Main from "@/views/Main.vue";

export default {
  path: "/profile",
  name: "profile",
  component: Main,
  children: [
    {
      path: "edit",
      name: "editProfile",
      component: () => import("../Pages/EditProfile.vue")
    },
    {
      path: "change-password",
      name: "changePassword",
      component: () => import("../Pages/ChangePassword.vue")
    }
  ]
};
