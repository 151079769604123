export default {
  getOfflineData (state) {
    return state.offlineData;
  },
  getCategories (state) {
    return state.category;
  },
  getInstructors (state) {
    return state.instructor;
  },
  getTrainingPaths (state) {
    return state.trainingPaths;
  }
}