import Request from "../../../../../../../core/Classes/Request";
import { fetchQuestionsApi, editQuestionsApi, editAnswerApi, addQuestionApi } from "./Questions.api";
import BaseService from "../../../../../../../core/Classes/BaseService";

export default class QuestionsService extends BaseService {

   static fetchQuestions (id) {
    
    return Request.get(fetchQuestionsApi + id + '/question/').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  static addQuestion (data) {
    console.log(data)
    
    return Request.post(addQuestionApi, data).then((res) => {
      console.log(res)
      if (res.response) {
        this.resolveResponse(
          res,
          "تمت إضافة المعلم بنجاح",
          JSON.stringify(res.response.error)
        );  
      }
      this.resolveResponse(
        res,
        "تمت الإضافة بنجاح",
        "حدث خطأ أثناء الإضافة"
      );
     
      return res;
    })
  }
 
  static updateQuestion (payload) {
    console.log(payload)
 
    return Request.patch(editQuestionsApi + payload.id, payload.data).then((res) => {
      this.resolveResponse(
        res,
        "تم التعديل بنجاح",
        "حدث خطأ أثناء التعديل"
      );
      return res;
    });
  }

  static addAnswers (payload) {
    
    return Request.post(addQuestionApi + payload.id, payload.data).then((res) => {
      console.log(res)
      if (res.response) {
        this.resolveResponse(
          res,
          "تمت الإضافة بنجاح",
          JSON.stringify(res.response.error)
        );  
      }
      this.resolveResponse(
        res,
        "تمت الإضافة بنجاح",
        "حدث خطأ أثناء الإضافة"
      );
     
      return res;
    })
  }

  static updateAnswer (payload) {
    console.log(payload)
 
    return Request.patch(editAnswerApi + payload.id, payload.data).then((res) => {
      this.resolveResponse(
        res,
        "تم التعديل بنجاح",
        "حدث خطأ أثناء التعديل"
      );
      return res;
    });
  }

  static deleteQuestion (id) {

    return Request.delete(editQuestionsApi + id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static deleteAnswer (id) {

    return Request.delete(editAnswerApi + id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}