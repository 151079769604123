import InstructorsService from "../Services/instructors.service";

export default {
  
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchInstructors({ commit }, queryParams) {
    commit("SET_LOADING_STATUS", true)
    return new Promise((resolve, reject) => {
      InstructorsService.fetchInstructors()
        .then((response) => {
          console.log(response.data.response)
          commit("SET_INSTRUCTORS", response.data.response);
          resolve(response);
          commit("SET_LOADING_STATUS", false)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchSingleInstructor({ commit }, id) {
    return new Promise((resolve, reject) => {
      InstructorsService.fetchSingleInstructor(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_SINGLE_INSTRUCTOR", response.data.response.user);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the contact messages 
   */
  fetchInstructorsWithSearch ({ commit }, data) {
    console.log(data.searchTerm)
    commit("SET_LOADING_STATUS", true)
    return new Promise((resolve, reject) => {
      InstructorsService.fetchInstructorsWithSearch(data.searchTerm)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_INSTRUCTORS", response.data.response);
          resolve(response);
          commit("SET_LOADING_STATUS", false)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc create new instructor 
   */
  addInstructor ({ _, dispatch }, data) {
    return new Promise((resolve, reject) => {
      InstructorsService.addInstructor(data)
        .then((res) => {
          dispatch('fetchInstructors');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc update an instructor
   */
  updateInstructor({ _, dispatch }, payload ) {
    return new Promise((resolve, reject) => {
      InstructorsService.updateInstructor(payload)
        .then((response) => {
          dispatch('fetchSingleInstructor', payload.id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },



  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc delete an instructor
   */
  deleteInstructor ({ _, dispatch }, data) {
    return new Promise((resolve, reject) => {
      InstructorsService.deleteInstructor(data.id)
        .then((response) => {
          dispatch('fetchInstructors');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

}