import state from './Courses.state'
import getters from './Courses.getters'
import mutations from './Courses.mutationTypes'
import actions from './Courses.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}