import Request from "../../../core/Classes/Request";
import {
  trainingPathsApi,
  totalCoursesCountApi_1,
  totalCoursesCountApi_2,
  trainingPathCourses_1,
  trainingPathCourses_2,
  coursesListApi_1,
  coursesListApi_2,
  onlineCourseApi,
  addCourseToWishListApi,
  getAttendenceRateApi,
  getLectureApi,
  getModuleQAApi,
  senAnswersApi
} from "./CourseArea.api";
import BaseService from "../../../core/Classes/BaseService";

export default class CourseAreaService extends BaseService {
  // -------learningPath-------
  /**
   * @param _
   * @desc getTrainingPaths function
   * @author Hamza Sweid
   * @task #BACT-191
   * */
  static getTrainingPaths() {
    return Request.get(trainingPathsApi).then((res) => {
      return res;
    });
  }

  // -------coursesList-------
  /**
   * @param category_id
   * @desc getCoursesList function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  static getCoursesList(id) {
    return Request.get(coursesListApi_1 + id + coursesListApi_2).then((res) => {
      return res;
    });
  }

  // -------trainingPathList-------
  /**
   * @param trainingPath_id
   * @desc getTotalCoursesCount function
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  static getTotalCoursesCount(id) {
    return Request.get(
      totalCoursesCountApi_1 + id + totalCoursesCountApi_2
    ).then((res) => {
      return res;
    });
  }

  /**
   * @param trainingPath_id
   * @desc getTrainingPathCourses function
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  static getTrainingPathCourses(id) {
    return Request.get(trainingPathCourses_1 + id + trainingPathCourses_2).then(
      (res) => {
        return res;
      }
    );
  }

  // onlineCourse
  /**
   * @param course_id
   * @desc getOnlineCourse function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  static getOnlineCourse(id) {
    return Request.get(onlineCourseApi + id).then((res) => {
      return res;
    });
  }

  /**
   * @param course_id
   * @desc addCourseToWishList function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  static addCourseToWishList(id) {
    return Request.post(addCourseToWishListApi, id).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.addedToWishList",
        "resolveResponse.somethingWentWrong"
      );
      return res;
    });
  }

  /**
   * @param lecture_id
   * @desc getLecture function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  static getLecture(id) {
    return Request.get(getLectureApi + id).then((res) => {
      return res;
    });
  }

  /**
   * @param lecture_id
   * @desc getAttendenceRate function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  static getAttendenceRate(id) {
    return Request.get(`${getAttendenceRateApi}course_id=${id}`).then((res) => {
      return res;
    });
  }

  /**
   * @param course_id
   * @desc getCourseModules function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  static getCourseModules(id) {
    return Request.get(`api/course/${id}/module`).then((res) => {
      return res;
    });
  }

  /**
   * @param module_id
   * @desc getModuleQA function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  static getModuleQA(id) {
    return Request.get(`${getModuleQAApi}module_id=${id}&size=99`).then(
      (res) => {
        return res;
      }
    );
  }

  /**
   * @param data
   * @desc sendAnswers function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  static sendAnswers(data) {
    return Request.post(senAnswersApi, data).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.yourAnswersHaveSubmitted",
        "resolveResponse.failedToSendAnswers"
      );
      return res;
    });
  }
}
