import state from './courses.state'
import actions from './courses.actions'
import mutations from './courses.mutations'
import getters from './courses.getters'
import Modules from '../Pages/Modules/Store/index'
import Questions from '../Pages/Questions/Store/index'

export default
{
    namespaced:true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        Modules,
        Questions
    }
}