<template>
  <section
    class="main-nav-section uk-background-white uk-container  uk-des-nav"
  >
    <nav class="uk-navbar-container uk-des-nav uk-background-white" uk-navbar>
      <div class="uk-navbar-left uk-width-1-1">
        <nav-bar-links></nav-bar-links>
      </div>
      <div
        class="uk-navbar-right uk-width-1-2 d-inline-flex justify-center align-center"
      >
        <div
          uk-grid
          class="uk-width-1-1 uk-search uk-search-default uk-margin-small-top d-inline-flex justify-center align-center"
        >
          <div class="uk-width-1-6 pl-0">
            <base-badge
              class="d-none"
              :style="{ color: this.primaryColor }"
            ></base-badge>
          </div>
          <div class="uk-width-auto uk-position-relative">
            <router-link
              v-if="search"
              :to="'/search?page=0&size=99&lang=ar&q=' + search"
              class="uk-search-icon-flip mx-2 px-5"
              uk-search-icon
              :style="{ color: this.primaryColor }"
            >
              <span>ابحث</span>
            </router-link>
            <span
              v-else
              class="uk-search-icon-flip"
              :style="{ color: this.primaryColor }"
              uk-search-icon
            ></span>
            <input
              @keyup.enter="
                $router.push({
                  path: `/search?page=0&size=99&lang=ar&q=${search}`
                })
              "
              class="uk-width-1-1 uk-search-input"
              v-model="search"
              type="search"
              :placeholder="$t('navBar.search')"
            />
          </div>
        </div>
      </div>
      <div
        class="uk-navbar-right uk-width-1-3 d-inline-flex justify-center align-center"
      >
        <div>
          <img :src="logoImg" class="logo-img" />
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import BaseBadge from "../Base/Badge/BaseBadge";
import NavBarLinks from "./NavBarLinks";

export default {
  name: "NavBar",

  components: { NavBarLinks, BaseBadge },

  data() {
    return {
      logoImg: require("../../assets/img/LOGO.png"),
      search: ""
    };
  }
};
</script>

<style lang="scss">
.main-nav-section {
  :lang(ar) {
    direction: ltr;
  }
  background-color: var(--white-color) !important;
  .uk-navbar-nav li a {
    font-size: 18px;
    font-weight: bold;
  }

  .uk-navbar-nav li {
    margin: 0 16px;
  }
  .uk-search input {
    border-radius: 20px;
    border: 0;
    text-align: left;
    box-shadow: 0px 5px 10px 3px var(--black-shadow);
  }
  .uk-search-icon-flip span {
    font-size: 19px !important;
  }
}
</style>
