import SlidersService from "../Services/Sliders.service";

export default {
  
  getSliders({ commit }) {
    return new Promise((resolve, reject) => {
      SlidersService.getSliders()
        .then((response) => {
          commit("SET_SLIDERS", response.data.response);
          console.log(response.data.response);
          resolve(response.data.response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addSlider({ commit , dispatch }, data) {
    return new Promise((resolve, reject) => {
      SlidersService.addSlider(data)
        .then((response) => {
          console.log("addSlider");
          dispatch('getSliders');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  editSlider({ commit , dispatch }, data) {
    return new Promise((resolve, reject) => {
      SlidersService.editSlider(data)
        .then((response) => {
          console.log("editSlider");
          dispatch('getSliders');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  deleteSlider({ commit  , dispatch }, id) {
    return new Promise((resolve, reject) => {
      SlidersService.deleteSlider(id)
        .then((response) => {
          console.log("deleteSlider");
          dispatch('getSliders');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
}