import { offline } from "../Services/courses.api"

export default {
    SET_COURSES(state, courses) {
        state.coursesArray = courses
    },
    SET_COURSE(state,course) {
        state.course = course
    },
    SET_OFFLINE_COURSES (state, offlineCourses) {
        state.offlineCourses = offlineCourses;
    }
}
