import state from './Students.state'
import getters from './Students.getters'
import mutations from './Students.mutationTypes'
import actions from './Students.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}