import CourseInfoModalService from "../Services/courseInfoModal.service";

export default {
  /**
   * @param commit
   * @param course_id
   * @desc selectCourseModal function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  selectCourseModal({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseInfoModalService.selectCourseModal(id)
        .then((res) => {
          commit("SELECT_COURSE_MODAL", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param course_id
   * @desc addCourseToWishList function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  addCourseToWishList({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseInfoModalService.addCourseToWishList(id)
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            commit("SET_WISHED");
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param course_id
   * @desc enrollCourse function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  enrollCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseInfoModalService.enrollCourse(id)
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            commit("SET_ENROLLED");
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  }
};
