<template>
  <div>
    <section class="loader">
      <div class="uk-position-center ">
        <img class="uk-img " :src="circleImg" alt="">
      </div>
    </section>
    <section class="splashScreen-section">
      <div class="uk-parent">
        <img class="uk-logo-splash" :src="splashScreenImg" alt="">
        <img class="uk-hello-splash" :src="HelloImg" alt="">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
  data() {
    return {
      circleImg : require('../../assets/img/circle.png'),
      splashScreenImg : require('../../assets/img/splashScreen/logo.png'),
      HelloImg : require('../../assets/img/splashScreen/Hello.png'),
    }
  }
}
</script>

<style scoped>

</style>
