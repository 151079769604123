import Request from "../../../../../core/Classes/Request";
import { getInstructorsApi, newInstructorApi } from "./instructors.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class InstructorsService extends BaseService {

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructors 
   */
  static fetchInstructors (queryParams) {
    let a = '?page=0&size=100&q='
    return Request.get(getInstructorsApi + a, queryParams).then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructors 
   */
  static fetchSingleInstructor (id) {
    return Request.get(getInstructorsApi + id).then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @desc add a Partner 
   * @author Ibrahim Zanbily
   * @task #BACT-208-test
   * */
  static addInstructor (data) {
    console.log(data)
    const formdata = new FormData();
    formdata.append("first_name",data.firstName);
    formdata.append("last_name",data.lastName);
    formdata.append("email",data.email);
    formdata.append("password", data.password);
    formdata.append("phone",data.phone);
    formdata.append("country",data.country);
    formdata.append("identity_number",data.identityNumber);
    formdata.append("birth_date",data.birth_date);
    console.log(data.avatar)
    if (data.avatar)
    formdata.append("avatar",data.avatar);

    return Request.post(newInstructorApi, formdata).then((res) => {
      if (res.response) {
        this.resolveResponse(
          res,
          "تمت إضافة المعلم بنجاح",
          JSON.stringify(res.response.data.message)
        );  
      }
      this.resolveResponse(
        res,
        "تمت إضافة المعلم بنجاح",
        "حدث خطأ أثناء الإضافة"
      );
     
      return res;
    })
  }
  /**
   * @desc Update an instructor // waiting for the backend to add avatar
   * @author Ibrahim Zanbily
   * @task #BACT-208-test
   * */
  static updateInstructor (payload) {
    console.log(payload)
    const formdata=new FormData();
    formdata.append("firstName",payload.data.firstName);
    formdata.append("lastName",payload.data.lastName);
    formdata.append("email",payload.data.email);
    formdata.append("phone",payload.data.phone);
    formdata.append("country",payload.data.country);
    formdata.append("identityNumber",payload.data.identityNumber);
    console.log(payload.data.birth_date)
    formdata.append("birthDate",payload.data.birth_date);
    console.log(typeof payload.data.avatar)
    if (payload.data.avatar)
    formdata.append("avatar",payload.data.avatar);
 
    return Request.patch(getInstructorsApi + payload.id, formdata).then((res) => {
      this.resolveResponse(
        res,
        "تم تعديل المعلم بنجاح",
        "حدث خطأ أثناء تعديل المعلم"
      );
      return res;
    });
  }


  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructors 
   */
  static fetchInstructorsWithSearch (searchTerm) { 
    let a = '?page=0&size=8&q='
    return Request.get(getInstructorsApi + a + searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc delete an instructor 
   */
  static deleteInstructor (id) {
    return Request.delete(getInstructorsApi + id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

}