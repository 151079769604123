export default {
  TOGGLE_MENU_STATUS(state) {
    state.menuIsOpen = !state.menuIsOpen;
  },
  SET_CERTIFICATES(state, certificates) {
    state.certificates = certificates;
  },
  SET_FAQS(state, FAQs) {
    state.FAQs = FAQs;
  },
  SET_FAQS_COUNT(state, faqsCount) {
    state.faqsCount = faqsCount;
  },
  SET_SLIDERS(state , sliders){
    state.sliders = sliders;
  },
};
