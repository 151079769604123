import instructorDashBoardService from "../Services/instructorDashBoard.service";
export default {
    fetchCategories({ commit }) {
        return new Promise((resolve, reject) => {
            instructorDashBoardService.fetchCategories()
                .then((res) => {
                    commit("SET_CATEGORIES", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchCountersInstructor({ commit }) {
        return new Promise((resolve, reject) => {
            instructorDashBoardService.fetchCounters()
                .then((res) => {
                    console.log('from actions', res.data.response)
                    commit("SET_COUNTERS_INSTRUCTOR", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchStudentsInstructor({ commit }) {
        return new Promise((resolve, reject) => {
            instructorDashBoardService.fetchStudents()
                .then((res) => {
                    console.log('from actions', res.data.response)
                    commit("SET_STUDENTS_INSTRUCTOR", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
}
