export default {
  getStudents(state) {
    return state.students;
  },
  getStudInstructors(state) {
    return state.instructors;
  },
  getStudCourses(state) {
    return state.courses;
  },
  getStudInfo(state) {
    return state.student;
  },
  loadingStudents(state) {
    return state.loadingStudents;
  },
  loadingStudentInstructors(state) {
    return state.loadingStudentInstructors;
  },
  loadingStudentCourses(state) {
    return state.loadingStudentInstructors;
  },
};
