import state from './info.state';
import actions from './info.action';
import mutations from './info.mutationTypes';
import getters from './info.getters';
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author Obada Saleh
 * @task #BACT-73
 * */
export default{
  state,
  actions,
  mutations,
  getters,
  modules:{}
}
