export default {
  getInstructors (state) {
    return state.instructors;
  },
  getSingleInstructor (state) {
    return state.singleInstructor;
  },
  loadingInstructors (state) {
    return state.loadingInstructors;
  }
}