import AboutUsService from "../Services/aboutUs.service";

export default {

  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc get the about bact information (Image and Message) 
   */
  getImgAndMsg({ commit }) {
    console.log('fetch')
    return new Promise((resolve, reject) => {
      AboutUsService.getImgAndMsg()
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            commit("SET_IMG_AND_MSG", res.data.response);
            resolve(res);
          } else {
            console.log("fail");
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  async fetchAboutUs({ commit }) {
    commit("SET_LOADING",true)
    try {
        let response = await AboutUsService.getImgAndMsg();
        await commit("SET_IMG_AND_MSG", response.data.response);
    } catch (error) {
        console.log(error);
    }
    commit("SET_LOADING",false)

},
  
  updateAboutUs({ _,dispatch }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      AboutUsService.updateAboutUs(data)
        .then((res) => {
           dispatch('getImgAndMsg');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

}