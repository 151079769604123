
export default {
    SET_FAQS(state, FAQs) {
      
        state.FAQs = FAQs;
      },
      SET_FAQS_COUNT(state, faqsCount) {
        state.faqsCount = faqsCount;
      },
     SET_LOADING(state,isloading) {
       state.loadingFaqs = isloading;
     }

}