import Dashboard from "@/api/dashboard";
import DashBoardService from "../Services/dashboard.service";

const dashboard = new Dashboard();

export default {
  fetchCertificates({ commit }) {
    return new Promise((resolve, reject) => {
      dashboard
        .fetchCertificates()
        .then((response) => {
          commit("SET_CERTIFICATES", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchFAQs({ commit }) {
    return new Promise((resolve, reject) => {
      DashBoardService.fetchFAQs()
        .then((response) => {
        
          commit("SET_FAQS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addFAQ({ commit,dispatch }, data) {
    return new Promise((resolve, reject) => {
      DashBoardService.addFAQ(data)
        .then((res) => {
          dispatch('fetchFAQs');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  editFAQ({ _,dispatch }, data) {
    return new Promise((resolve, reject) => {
      DashBoardService.editFAQ(data)
        .then((res) => {
           dispatch('fetchFAQs');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  deleteFAQs({_,dispatch}, data) {
    return new Promise((resolve, reject) => {
      DashBoardService.deleteFAQs(data)
      .then((res) => {8
        dispatch('fetchFAQs');
        resolve(res);
      })
      .catch((err) => reject(err));
    })
  },
  FAQsCount({ commit }) {
    return new Promise((resolve, reject) => {
      DashBoardService.FAQsCount()
        .then((res) => {
          commit("SET_FAQS_COUNT", response.data.response);
          console.log(response.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  changeMenuStatus({ commit }) {
    commit('TOGGLE_MENU_STATUS')
  },
  
};


