export default {
  /**
   * @param state
   * @param modules
   * @desc SET_COURSE_MODULES mutation
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  SET_COURSE_MODULES(state, modules) {
    state.modules = modules;
  },

  /**
   * @param state
   * @param moduleQA
   * @desc SET_MODULE_Q_A mutation
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  SET_MODULE_Q_A(state, moduleQA) {
    state.moduleQA = moduleQA;
    state.moduleTitle = moduleQA[0].module.title;
  },

  /**
   * @param state
   * @param course_title
   * @desc SET_COURSE_TITLE mutation
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  SET_COURSE_TITLE(state, courseTitle) {
    state.courseTitle = courseTitle;
  },

  /**
   * @param state
   * @param result
   * @desc SET_RESULT mutation
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  SET_RESULT(state, response) {
    state.userPercentage = response.userPercentage;
    state.correctAnswers = response.correct_answers;
    state.correctAnswersCount = response.correct_answers_count;
  }
};
