import state from "./home.state";
import getters from "./home.getters";
import mutations from "./home.mutationTypes";
import actions from "./home.actions";
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
