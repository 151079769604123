import state from './FAQs.state'
import getters from './FAQs.getters'
import mutations from './FAQs.mutation'
import actions from './FAQs.action'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}