import Colors from './GlobalColors';
import Icons from './Icons';
import Auth from './Auth';
import General from './General';
import Vue from 'vue'

Vue.prototype.$Icons = Icons;

Vue.mixin(Colors);
Vue.mixin(Icons);
Vue.mixin(Auth);
Vue.mixin(General);