export default {
  /**
   * @param state
   * @param category
   * @desc SET_COURSES_LIST mutation
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  SET_COURSES_LIST(state, coursesCategory) {
    state.courses = coursesCategory.courses;
    state.category = coursesCategory.category;
  },
  
};
