export const trainingPathsApi = `api/training-path/`;

// -------coursesList-------
export const coursesListApi_1 = `api/category/`;
export const coursesListApi_2 = `/courses?page=0&size=99`;

// trainingPathList
export const totalCoursesCountApi_1 = `api/training-path/`;
export const totalCoursesCountApi_2 = `/count`;
export const trainingPathCourses_1 = `api/training-path/`;
export const trainingPathCourses_2 = `/courses?page=0&size=99`;

// onlineCourse
export const onlineCourseApi = "api/course/";
export const addCourseToWishListApi = "api/wishes/add";
export const getAttendenceRateApi = "api/course/online/getAttendanceRate?";
export const getLectureApi = "api/lecture/";

// ModulesCourses
export const getModuleQAApi = "api/question/getModuleQuestions?";
export const senAnswersApi = "api/question/test";
