import CoursesService from "../Services/Courses.service";

export default {
  //        INSTRUCTOR COURSES SECTION
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchCourses({ commit }, id) {
    return new Promise((resolve, reject) => {
      CoursesService.fetchCourses(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's courses 
   */
  fetchCoursesWithSearch ({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      CoursesService.fetchCoursesWithSearch(data)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },



  //        PENDING COURSES ACTIONS
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchPendingCourses({ commit }, id) {
    return new Promise((resolve, reject) => {
      CoursesService.fetchPendingCourses(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_PENDING_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's courses 
   */
  fetchPendingCoursesWithSearch ({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      CoursesService.fetchPendingCoursesWithSearch(data)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_PENDING_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

   // COUNTERS /////////////

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchPendingCoursesCount({ commit }, id) {
    
    return new Promise((resolve, reject) => {
      CoursesService.fetchPendingCoursesCount(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_PENDING_COURSES_COUNT", response.data.response);
          resolve(response);

        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}