<!-- Bact-45-->
<!-- BACT-FIX-DESIGN-1 @AUTH  karam mustafa -->

<template>
  <footer class="uk-footer uk-background-cover" :style="footerBackImg">
    <div class="uk-container">
      <div
        class="uk-child-width-1-1 uk-child-width-1-2@s footer-main-list-area uk-child-width-1-4@m uk-m-bt"
        uk-grid
      >
        <!-- about bact -->
        <div>
          <p class="desc">
            <span>{{ $t("footer.bact") }}</span> {{ MainFooter.message }}
          </p>
        </div>
        <!-- links -->
        <div>
          <h4>{{ $t("footer.links.usfulLinks") }}</h4>
          <ul class="uk-list">
            <li>
              <router-link :to="{ name: 'aboutUs' }">{{
                $t("footer.links.aboutBact")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'signUp' }">{{
                $t("footer.links.signup")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'contactUs' }">{{
                $t("footer.links.contactUs")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'faqs' }">{{
                $t("navBar.FAQ")
              }}</router-link>
            </li>
          </ul>
        </div>
        <!-- policy -->
        <div>
          <h4>{{ $t("footer.termsAndConditions.termsAndConditions") }}</h4>
          <ul class="uk-list">
            <li>
              <a href="#">{{
                $t("footer.termsAndConditions.privacyPolicy")
              }}</a>
            </li>
          </ul>
        </div>
        <!-- @copy -->
        <div class="uk-laste-part">
          <div class="footer-logo-img-area center-with-flex">
            <img :src="footerLogo" />
          </div>
          <div class="footer-logo-p-area center-with-flex uk-margin-small-top">
            <p>{{ $t("footer.copy") }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- payment method -->
    <div
      class="uk-container w-100 uk-m-top mt-5 footer-pay-paid-icons-section"
      uk-grid
    >
      <div
        class="uk-width-1-2 footer-pay-paid-section uk-bg-white uk-margin-auto"
        :class="$i18n.locale === 'en' ? 'en-border' : ''"
      >
        <ul class="uk-iconnav w-100">
          <li>
            <a href="#"> <img :src="demoImg1"/></a>
          </li>
          <li>
            <a href="#"> <img :src="demoImg2"/></a>
          </li>
          <li>
            <a href="#"> <img :src="demoImg3"/></a>
          </li>
          <li>
            <a href="#"> <img :src="demoImg4"/></a>
          </li>
          <li>
            <a href="#"> <img :src="demoImg5"/></a>
          </li>
        </ul>
      </div>
      <div class="uk-width-1-2 icon-area center-with-flex uk-bg-icon">
        <ul class="uk-iconnav">
          <li>
            <a href="#">
              <v-icon>{{ this.linkedinIcon }}</v-icon>
            </a>
          </li>
          <li>
            <a href="#">
              <v-icon>{{ this.twitterIcon }}</v-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MainFooter",

  data: function() {
    return {
      footerBackImg: require("../../../assets/img/footer/Untitled-2-01.png"),
      footerLogo: require("../../../assets/img/footer/Logo-f.png"),
      demoImg1: require("../../../assets/img/footer/bg.png"),
      demoImg2: require("../../../assets/img/footer/visa.png"),
      demoImg3: require("../../../assets/img/footer/MasterCard.png"),
      demoImg4: require("../../../assets/img/footer/Apple_Pay.png"),
      demoImg5: require("../../../assets/img/footer/Mada.png"),
      en_border: "border-radius:0 20px 20px 0"
    };
  },

  methods: {
    ...mapActions("MainFooter", ["getImgAndMsg", "getAccounts"])
  },

  created() {
    /**
     * @desc getImgAndMsg function
     * @author Hamza Sweid
     * @task #BACT-188
     * */
    this.getImgAndMsg()
      .then()
      .catch((err) => console.log(err));
    this.getAccounts()
      .then()
      .catch((err) => console.log(err));
  },

  computed: {
    ...mapState(["MainFooter"])
  }
};
</script>

<style lang="scss" scope>
@import "../../../assets/css/footer.scss";
.uk-footer {
  :lang(ar) {
    direction: ltr;
  }
}

.footer-pay-paid-section {
  padding: 0 !important;
}
@media (max-width: 992px) {
  .footer-pay-paid-icons-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-pay-paid-section {
    width: 98% !important;
  }
}
.footer-pay-paid-section .uk-iconnav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.center-with-flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.icon-area {
  justify-content: flex-end !important;
}
.icon-area .uk-iconnav li a span,
.footer-main-list-area div ul li a {
  color: var(--white-color);
}
.uk-footer .uk-m-top .uk-bg-white img {
  width: 50px !important;
}
.en-border {
  border-radius: 0 20px 20px 0 !important;
}
</style>
