import Request from "@/core/Classes/Request";
import { getCategories, counters, students, getInstructorInfo } from "./instructorDashBoard.api";
import BaseService from "@/core/Classes/BaseService";

export default class instructorDashBoardService extends BaseService {
    /**
 * @desc fetchCategories function
 * @author Obada Saleh
 * @task #BACT-189
 * */
    static fetchCategories() {
        return Request.get(getCategories).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static fetchCounters() {
        return Request.get(counters).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static fetchStudents() {
        return Request.get(students).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }

}