import CourseAreaService from "../../../Services/CourseArea.service";

export default {
  /**
   * @param commit
   * @param course_id
   * @desc getCourseModules function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  getCourseModules({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getCourseModules(id)
        .then((res) => {
          commit("SET_COURSE_MODULES", res.data.response.modules);
          commit("SET_COURSE_TITLE", res.data.response.course_title);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @param module_id
   * @desc getModuleQA function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  getModuleQA({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getModuleQA(id)
        .then((res) => {
          commit("SET_MODULE_Q_A", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param data
   * @desc sendAnswers function
   * @author Hamza Sweid
   * @task #BACT-221
   * */
  sendAnswers({ commit }, data) {
    return new Promise((resolve, reject) => {
      CourseAreaService.sendAnswers(data)
        .then((res) => {
          commit("SET_RESULT", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
