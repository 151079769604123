import state from './auth.state';
import getters from './auth.getters';
import mutations from './auth.mutationTypes';
import actions from './auth.actions';
export default {
  namespaced : true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
