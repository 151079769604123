import state from "./courseInfoModal.state";
import getters from "./courseInfoModal.getters";
import mutations from "./courseInfoModal.mutationTypes";
import actions from "./courseInfoModal.actions";
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
