import Request from "../../../../../../../core/Classes/Request";
import { fetchModulesApi, createModuleApi, getSingleModuleApi, editDeleteModuleApi, fetchLecturesApi, fetchSingleLectureApi, editDeleteLectureApi, createLectureApi } from "./Modules.api";
import BaseService from "../../../../../../../core/Classes/BaseService";

export default class ModulesService extends BaseService {

   static fetchData (payload) {
    let api = null;
    let suffix = null;
    switch (payload.type) {
      case 'module': 
        api = fetchModulesApi; 
        suffix = '/module/';
        break;
      case 'lecture':
        api = fetchLecturesApi;
        suffix = '/lecture/';
        break;
    }
    return Request.get(api + payload.id + suffix).then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  static fetchSingleData (payload) {

    let api = null;
    switch (payload.type) {
      case 'module': 
        api = getSingleModuleApi; 
        break;
      case 'lecture':
        api = fetchSingleLectureApi;
        break;
    }

    return Request.get(api + payload.id).then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  static addData (payload) {
    console.log(payload.data)
    
    let formData = new FormData;
    let api = null;
    switch (payload.type) {
      case 'module': 
        api = createModuleApi; 
        break;
      case 'lecture':
        formData.append('title', payload.data.title)
        formData.append('type', payload.data.type)
        formData.append('module_id', payload.data.module_id)
        formData.append('description', payload.data.description)
        formData.append('order', payload.data.order)
        formData.append('language', payload.data.language)
        formData.append('file', payload.data.file)
        api = createLectureApi;
        break;
    }

    return Request.post(api, payload.type==='module' ? payload.data : formData).then((res) => {
      if (res.response) {
        this.resolveResponse(
          res,
          "تمت إضافة المعلم بنجاح",
          JSON.stringify(res.response.data.message)
        );  
      }
      this.resolveResponse(
        res,
        "تمت الإضافة بنجاح",
        "حدث خطأ أثناء الإضافة"
      );
     
      return res;
    })
  }
 
  static updateData (payload) {
    console.log(payload)
    let formData = new FormData;
    let id = payload.data.id; // because it is not allowed
    payload.data.id = undefined;
    let data = null;
    let api = null;
    switch (payload.type) {
      case 'module': 
        api = editDeleteModuleApi; 
        data = payload.data
        break;
      case 'lecture':
        api = editDeleteLectureApi;
        formData.append('title', payload.data.title)
        formData.append('type', payload.data.type)
        formData.append('description', payload.data.description)
        formData.append('order', payload.data.order)
        if(payload.data.file)
        formData.append('file', payload.data.file)
        data = formData;
        break;
    }
 
    return Request.patch(api + id, data).then((res) => {
      this.resolveResponse(
        res,
        "تم التعديل بنجاح",
        "حدث خطأ أثناء التعديل"
      );
      return res;
    });
  }

  static deleteData (payload) {

    let api = null;
    switch (payload.type) {
      case 'module': 
        api = editDeleteModuleApi; 
        break;
      case 'lecture':
        api = editDeleteLectureApi;
        break;
    }

    return Request.delete(api + payload.id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}