export default {
  SET_INSTRUCTORS (state, data) {
    state.instructors = data;
  },
  SET_SINGLE_INSTRUCTOR (state, data) {
    state.singleInstructor = data;
  },
  SET_LOADING_STATUS (state, status) {
    state.loadingInstructors = status
  }
}