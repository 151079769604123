import CourseAreaService from "../../../Services/CourseArea.service";

export default {
  /**
   * @param commit
   * @desc getTrainingPaths function
   * @author Hamza Sweid
   * @task #BACT-191
   * */
  getTrainingPaths({ commit }) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getTrainingPaths()
        .then((res) => {
          commit("SET_TRAINING_PATHS", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
