import state from './main.state'
import getters from './main.getters'
import mutations from './main.mutationTypes'
import actions from './main.actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}