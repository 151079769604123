import Request from "../../../../../core/Classes/Request";
import { addCategoryApi, getCategoriesApi, editCategoryApi } from "./Categories.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class CategoryService extends BaseService {
    
    static async fetchData() {
        let fetchData = await Request.get(getCategoriesApi);
        return fetchData;
    }

    static async addCategory(data) {
        
        let addCategory = await Request.post(addCategoryApi, data);

        await this.resolveResponse({ ...addCategory },
            "تم أضافة الصنف بنجاح",
            "حدث خطأ أثناء أضافة الصنف");

        return addCategory;
    } 

    static async editCategory(id, data) {
     
        let url = editCategoryApi
        // url += `${id}`
        let editCategory = await Request.patch(url,data);
        await this.resolveResponse(editCategory,
            "تم تعديل الصنف بنجاح",
            "حدث حطأ أثناء تعديل الصنف");
        return editCategory;
    }
    static async deleteCategory(id) {
        let url = editCategoryApi;
        url += `/${id}`
        let deleteData = await Request.delete(url);
        await this.resolveResponse(deleteData,
            "تم حذف الصنف بنجاح",
            "حدث خطأ اثناء حذف الصنف");
        return deleteData;
    }

}