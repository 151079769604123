<script>
export default {
  data(){
    return {
      primaryColor: "#69d1b6",
      primaryColorOverlay: "#69d1b6a6",
      grayColor: "#f0f0f0",
      grayColorCard: "#f8f8f8",
      whiteColor: "#fff",
      blackShadow: "#a7a7a71e",
      darkColor: "#707070",
      BColor: "#00d4ff",
      YColor: "#ffa950",
      RColor: "#f00f54",
      fontWeightBold : "#606060",
      grayBack50 : "#c0c0c0",
    }
  }
};

</script>
