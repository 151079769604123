<script>
import {
  mdiAccount,
  mdiMenu,
  mdiYoutube,
  mdiChartPie,
  mdiEye,
  mdiPencil,
  mdiUpload,
  mdiPlus,
  mdiDownload,
  mdiLinkedin,
  mdiTwitter,
  mdiAccountSupervisor,
  mdiHome,
  mdiFileChart,
  mdiHomeAnalytics,
  mdiInbox,
  mdiCalendar,
  mdiApplicationSettings,
  mdiBatteryChargingMedium,
  mdiAccountCircleOutline,
  mdiAccountDetails,
  mdiLogout,
  mdiMagnify,
  mdiArrowRight,
  mdiDelete,
  mdiCamera,
  mdiTeach,
  mdiAccountBox,
  mdiClockAlert,
  mdiClose,
  mdiCommentQuestion,
  mdiCheck,
  mdiBook,
  mdiUndo,
  mdiSchool, 
  mdiArrowLeft,
  mdiViewList,
  mdiVideo
} from "@mdi/js";

export default {
  name: "Icons",
  data() {
    return { 
      accountIcon: mdiAccount,
      editIcon: mdiPencil,
      eyeIcon: mdiEye,
      pieIcon: mdiChartPie,
      uploadIcon: mdiUpload,
      plusIcon: mdiPlus,
      downloadIcon: mdiDownload,
      twitterIcon: mdiTwitter, 
      linkedinIcon: mdiLinkedin,
      youtubeIcon: mdiYoutube,
      menuIcon: mdiMenu,
      accountSupervisorIcon: mdiAccountSupervisor,
      homeIcon: mdiHome,
      fileIcon: mdiFileChart,
      mediaIcon: mdiBatteryChargingMedium,
      analyticIcon: mdiHomeAnalytics,
      inboxIcon: mdiInbox,
      calenderIcon: mdiCalendar,
      settingIcon: mdiApplicationSettings,
      mdiAccountCircleOutline: mdiAccountCircleOutline,
      profileIcon: mdiAccountDetails,
      mdiLogout: mdiLogout,
      searchIcon: mdiMagnify,
      rightArrowIcon : mdiArrowRight,
      leftArrowIcon : mdiArrowLeft,
      deleteIcon:mdiDelete,
      cameraIcon: mdiCamera,
      coursesIcon: mdiTeach,
      studentsIcon: mdiSchool,
      pendingIcon: mdiClockAlert,
      personalIcon: mdiAccountBox,
      closeIcon: mdiClose,
      qustionIcon: mdiCommentQuestion,
      answerIcon: mdiCheck,
      schoolIcon: mdiSchool,
      bookIcon: mdiBook,
      undoIcon: mdiUndo,
      settingsIcon: mdiViewList,
      videowIcon: mdiVideo
    }
  },
}
</script>
