import Request from "../../../core/Classes/Request";
import {
  userInfosApi,
  changePasswordApi,
  allWishesApi,
  studentCoursesApi,
  billsApi,
  payBillApi
} from "./profile.api";
import BaseService from "../../../core/Classes/BaseService";

export default class ProfileService extends BaseService {
  /**
   * @param _
   * @desc getUserInfos function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static getUserInfos() {
    return Request.get(userInfosApi).then((res) => {
      return res;
    });
  }

  /**
   * @param data
   * @desc editUserInfos function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static editUserInfos(data) {
    // console.log(data);
    // const formdata = new FormData();
    // formdata.append("first_name", data.firstName);
    // formdata.append("phone", data.phone);
    // if (data.avatar) formdata.append("avatar", data.avatar);
    // console.log(typeof formdata);
    // console.log(formdata);
    return Request.patch(userInfosApi, data).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.yourInfosHaveEdited",
        "resolveResponse.failedToEditInfos"
      );
      return res;
    });
  }

  /**
   * @param data
   * @desc changePassword function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static changePassword(data) {
    return Request.patch(changePasswordApi, data).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.YourPasswordHasChange",
        "resolveResponse.somethingWentWrong"
      );
      return res;
    });
  }

  /**
   * @param _
   * @desc getAllWishes function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static getAllWishes() {
    return Request.get(allWishesApi).then((res) => {
      return res;
    });
  }

  /**
   * @param id
   * @desc removeWish function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static removeWish(id) {
    return Request.delete(`api/wishes/${id}/remove`).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.wishListDeleted",
        "resolveResponse.somethingWentWrong"
      );
      return res;
    });
  }

  /**
   * @param _
   * @desc getStudentCourses function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static getStudentCourses(id) {
    return Request.get(studentCoursesApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getBills function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static getBills() {
    return Request.get(billsApi).then((res) => {
      return res;
    });
  }

  /**
   * @param _
   * @desc getBills function
   * @author Hamza Sweid
   * @task #BACT-209
   * */
  static payBill(id) {
    return Request.patch(`${payBillApi}${id}}`).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.youPayBill",
        "resolveResponse.somethingWentWrongWhilePaying"
      );
      return res;
    });
  }
}
