import CourseAreaService from "../../../Services/CourseArea.service";

export default {
  /**
   * @param commit
   * @param trainingPath_id
   * @desc getTotalCoursesCount function
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  getTotalCoursesCount({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getTotalCoursesCount(id)
        .then((res) => {
          commit("SET_TOTAL_COURESE_COUNT", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @param trainingPath_id
   * @desc getTrainingPathCourses function
   * @author Hamza Sweid
   * @task #BACT-205
   * */
  getTrainingPathCourses({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getTrainingPathCourses(id)
        .then((res) => {
          commit("SET_TRAINING_PATH_COURSES", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
