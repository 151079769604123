import CourseAreaService from "../../../Services/CourseArea.service";

export default {
  /**
   * @param commit
   * @param course_id
   * @desc getOnlineCourse function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  getOnlineCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getOnlineCourse(id)
        .then((res) => {
          commit("SET_ONLINE_COURSE", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param course_id
   * @desc addCourseToWishList function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  addCourseToWishList({ _ }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.addCourseToWishList(id)
        .then((res) => {
          let { status } = res;
          if (status < 300) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param lecture_id
   * @desc getLecture function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  getLecture({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getLecture(id)
        .then((res) => {
          commit("SET_LECTURE", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param lecture_id
   * @desc getAttendenceRate function
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  getAttendenceRate({ commit }, id) {
    return new Promise((resolve, reject) => {
      CourseAreaService.getAttendenceRate(id)
        .then((res) => {
          commit("SET_ATTENDENCE_RATE", res.data.response.attendance_rate);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
