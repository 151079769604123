export default {
    SET_CATEGORIES(state, categories) {
        state.categoriesArray = categories;
    },
    SET_COUNTERS_INSTRUCTOR(state, counters) {
        state.countersInstructor = counters
    },
    SET_STUDENTS_INSTRUCTOR(state, students) {
        state.studentsInstructor = students
    },

}
