<script>
  import {mapGetters} from 'vuex';
    export default {
        name: "Auth",
        computed:{
            ...mapGetters('Auth' , ['getIsLogIn']),
            /**
             * @desc this function is global for system , this will determine if actors is logged in
             * @author karam mustafa
             * @task #BACT-101
             * */
            $isLogin(){
                return this.getIsLogIn === true;
            }
        }
    }
</script>

