import Main from '@/views/Main';

export default {
  path: '/',
  component: Main,
  children: [
    {
      path: '/FAQs',
      name: 'faqs',
      component: () => import('../FAQs.vue'),
    }
  ]
}