import state from './Questions.state'
import getters from './Questions.getters'
import mutations from './Questions.mutationTypes'
import actions from './Questions.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}