/*
 * BACT-40
 * Author: Ali Ataf
 * Date: 20-10-2020
 */

import Main from "@/views/Main.vue";

export default {
  path: "/courses",
  component: Main,
  children: [
    {
      path: ":id",
      component: () => import("../Pages/OnlineCourse/OnlineCourse.vue")
    },
    {
      path: ":id/test/modules",
      component: () => import("../Pages/ModulesCourses/SelectModuleToTest.vue")
    },
    {
      path: ":id/test/modules/:moduleId",
      component: () => import("../Pages/ModulesCourses/ModuleTest.vue"),
    },
    {
      path: "list/:id",
      component: () => import("../Pages/CoursesList/CoursesList.vue")
    },
    {
      path: "categories",
      component: () => import("../../Home/Components/CourseCategories.vue")
    },
    {
      path: "/learning-path",
      component: () => import("../Pages/LearningPath/LearningPath.vue")
    },
    {
      path: "/course-info/:id",
      component: () => import("../Pages/CourseInfo/CourseInfo.vue")
    },
    {
      path: "/training-path-list/:id",
      component: () => import("../Pages/TrainingPathList/TrainingPathList.vue")
    }
  ]
};
