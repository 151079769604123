import Request from "@/core/Classes/Request";
import { getModules, addNewModule, addModuleTranslation, deleteModule, getModule, updateModule } from "./modules.api";
import BaseService from "@/core/Classes/BaseService";
export default class modulesService extends BaseService {
    /**
* @desc fetchCategories function
* @author Obada Saleh
* @task #BACT-189
* */
    static fetchModules(id) {
        return Request.get(`${getModules}${id}/module`).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static fetchSingleModule(id) {
        return Request.get(`${getModule}/${id}`).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }
    static addNewModule(data) {
        
        return Request.post(addNewModule, data).then((res) => {
            
            if (res.response?.data?.response?.error) {
                if (res.response.data.response.error.includes('must be a number')) {
                  this.resolveResponse(
                    res,
                    "تمت الإضافة بنجاح",
                    'الترتيب يجب أن يكون عدداً صحيحاً'
                  );  
                } else {
                  this.resolveResponse(
                    res,
                    "تمت الإضافة بنجاح",
                    JSON.stringify(res.response.data.response.error)
                  );  
                }
              } else if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تمت الإضافة بنجاح",
                    JSON.stringify(res.response.data.response)
                  );  
              }
              this.resolveResponse(
                res,
                "تمت الإضافة بنجاح",
                "حدث خطأ الإضافة"
              );
              return res;
        })
    }
    static addModueTranslation(data) {
        console.log("from services", data)
        return Request.patch(`${addModuleTranslation}${data.module_id}`, data).then((res) => {
            this.resolveResponse(res,
                "تم اضافة ترجمة للوحدة",
                " حدث خطأ أثناء اضافة ترجمة للوحدة"
            );
            return res
        });
    }
    static deleteModule(id) {
        return Request.delete(`${deleteModule}/${id}`).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم حذف الوحدة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم حذف الوحدة",
                " حدث خطأ أثناء حذف الوحدة"
            );
            return res
        });
    }
    static updateModule(data) {
        console.log("from services", data)
        return Request.patch(`${updateModule}${data.moduleId}`, data.data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم تعديل الوحدة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم تعديل الوحدة",
                " حدث خطأ أثناء تعديل الوحدة"
            );
            return res
        });
    }
}