import state from './Traning.state'
import getters from './Traning.getters'
import mutations from './Traning.mutation'
import actions from './Traning.action'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}