<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#399d83"
      :opacity="0.7"
      background-color="#ccc"
      :width="100"
      :height="100"
      :z-index="991"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "RequestLoader",

  components: {
    Loading
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    }
  }
};
</script>
