
import FAQsService from '../Services/FAQs.service'

export default {

  fetchFAQs({ commit }) {

    return new Promise((resolve, reject) => {
      FAQsService.fetchFAQs()
        .then((response) => {
          commit("SET_FAQS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-189-test2
   * @desc search the FAQs 
   */
  fetchFAQsWithSearch ({ commit }, data) {
    console.log(data.searchTerm)
    return new Promise((resolve, reject) => {
      FAQsService.fetchFAQsWithSearch(data.searchTerm)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_FAQS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

}