import state from './aboutUs.state'
import getters from './aboutUs.getters'
import mutations from './aboutUs.mutationTypes'
import actions from './aboutUs.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}