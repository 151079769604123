import StudentsService from '../Services/Students.services'
export default {
    getAllStudents({ commit }) {
        return new Promise((resolve, reject) => {
          StudentsService.getAllStudents()
            .then((response) => {
              commit("SET_STUDENTS", response.data.response);
              console.log(response.data.response);
              resolve(response.data.response);
              commit("SET_LOADING_STATUS", false)
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    
      getStudentInstructors({ commit }, id) {
        return new Promise((resolve, reject) => {
          StudentsService.getStudentInstructors(id)
            .then((response) => {
              commit("SET_STUDENT_INSTRUCTORS", response.data.response);
              console.log(response.data.response);
              resolve(response);
              commit("SET_LOADING_STATUS_INSTRUCTORS", false)
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      getStudentCourses({ commit }, id) {
        return new Promise((resolve, reject) => {
          StudentsService.getStudentCourses(id)
            .then((response) => {
              commit("SET_STUDENT_COURSES", response.data.response);
              console.log(response.data.response);
              resolve(response);
              commit("SET_LOADING_STATUS_COURSES", false)
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    
      getStudentInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
          StudentsService.getStudentInfo(id)
            .then((response) => {
              commit("SET_STUDENT", response.data.response);
              console.log(response.data.response);
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    
      editStudentPage({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
          StudentsService.editStudentPage(data)
            .then((response) => {
              console.log(response);
              dispatch("getAllStudents");
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },


}