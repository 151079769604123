import Request from "../../../../../core/Classes/Request";
import { getContactMessagesApi, contactMessagesApi } from "./contactUs.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class ContactUsService extends BaseService {

  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc get the contact messages 
   */
  static fetchContactMessages () {
    return Request.get(getContactMessagesApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc search the contact messages 
   */
  static fetchContactMessagesWithSearch (searchTerm) {
    return Request.get(getContactMessagesApi + '&q=' + searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static deleteMessage (id) {
    return Request.delete(contactMessagesApi + id).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}