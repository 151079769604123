import Request from "../../../core/Classes/Request";
import { sendMsgApi } from "./contactUs.api";
import BaseService from "../../../core/Classes/BaseService";
export default class ContactUsService extends BaseService {
  /**
   * @param msgData
   * @desc SendMsg function
   * @author Hamza Sweid
   * @task #BACT-180
   * */
  static sendMsg(data) {
    return Request.post(sendMsgApi, data).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.messageHasSent",
        "resolveResponse.failedToSendMessage"
      );
      return res;
    });
  }
}
