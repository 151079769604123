import state from "./learningPath.state";
import getters from "./learningPath.getters";
import mutations from "./learningPath.mutationTypes";
import actions from "./learningPath.actions";
export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
