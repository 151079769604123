export default {
  /**
   * @param state
   * @param onlineCourse
   * @desc SET_ONLINE_COURSE mutation
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  SET_ONLINE_COURSE(state, onlineCourse) {
    state.onlineCourse = onlineCourse;
    state.courseIndex = onlineCourse.online.index;
  },

  /**
   * @param state
   * @param lecture
   * @desc SET_LECTURE mutation
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  SET_LECTURE(state, lecture) {
    state.lecture = lecture;
  },

  /**
   * @param state
   * @param attendenceRate
   * @desc SET_ATTENDENCE_RATE mutation
   * @author Hamza Sweid
   * @task #BACT-204-3
   * */
  SET_ATTENDENCE_RATE(state, attendenceRate) {
    state.attendenceRate = attendenceRate;
  }
};
