import MainService from "../Services/main.service";

export default {

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc fetch the main dashboard counters (instructors, students, courses)
   */
  fetchCounters({ commit }) {
    return new Promise((resolve, reject) => {
      MainService.fetchCounters()
        .then((response) => {
          commit("SET_COUNTERS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc fetch the pending courses from backend
   */
  fetchPendingCourses({ commit }) {
    console.log('object')
    return new Promise((resolve, reject) => {
      MainService.fetchPendingCourses()
        .then((response) => {
          console.log('response',response)
          commit("SET_PENDING_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc search the pending courses 
   */
  fetchPendingCoursesWithSearch ({ commit }, data) {
    console.log(data.searchTerm)
    commit("SET_LOADING_STATUS", true)
    return new Promise((resolve, reject) => {
      MainService.fetchPendingCoursesWithSearch(data.searchTerm)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_PENDING_COURSES", response.data.response);
          resolve(response);
          commit("SET_LOADING_STATUS", false)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc get the Pending courses count 
   */
  fetchPendingCoursesCount({ commit }) {
    return new Promise((resolve, reject) => {
      MainService.fetchPendingCoursesCount()
        .then((response) => {
          console.log(response.data.response)
          commit("SET_PENDING_COURSES_COUNT", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc fetch the top 3 courses and number can be changed 
   */
  fetchTopCourses({ commit }, coursesNumber) {
    return new Promise((resolve, reject) => {
      MainService.fetchTopCourses(coursesNumber)
        .then((response) => {
          commit("SET_TOP_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc fetch the enrollment performance for the courses 
   */
  fetchEnrollmentPeroformance({ commit }, period) {
    return new Promise((resolve, reject) => {
      MainService.fetchEnrollmentPeroformance(period)
        .then((response) => {
          commit("SET_ENROLLMENT_PERFORMANCE", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateStatus({ _, dispatch }, data ) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      MainService.updateStatus(data)
        .then((response) => {
          dispatch('fetchPendingCourses');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchLiveCourses ({ commit }) {
    return new Promise((resolve, reject) => {
      MainService.fetchLiveCourses()
        .then((response) => {
          console.log('live courses',response)
          commit("SET_LIVE_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
  fetchDeniedCourses ({ commit }) {
    return new Promise((resolve, reject) => {
      MainService.fetchDeniedCourses()
        .then((response) => {
          commit("SET_DENIED_COURSES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}