
import AdminsService from '../Services/Admins.services'
export default {

    async getAllAdmins({ commit }) {
        commit("SET_LOADING",true)
        try {
            
            let response = await AdminsService.getAllAdmins();
            commit("SET_ALL_ADMINS", response.data.response);
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async createNormalSuperAdmin({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await AdminsService.createNormalSuperAdmin(data);
            await dispatch('getAllAdmins');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async updateNormalSuperAdmin({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await AdminsService.updateNormalSuperAdmin(data);
            await dispatch('getAllAdmins');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async deleteNormalSuperAdmin({ commit, dispatch }, id) {
        commit("SET_LOADING",true)
        try {
            let response = await AdminsService.deleteNormalSuperAdmin(id);
            await dispatch('getAllAdmins');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)
    },

}