import state from "./search.state";
import getters from "./search.getters";
import mutations from "./search.mutationTypes";
import actions from "./search.actions";
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
