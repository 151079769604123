export default {
    /**
       * @desc get categories
       * @author Obada Saleh
       * @task #BACT-73
       * */
    getModules(state) {
        return state.modules.modules
    },
    getModule(state) {
        return state.module
    },
}
