/**
 * @desc auth component apis routes
 * @author karam mustafa
 * @task #BACT-53
 * */

import Main from "@/views/Main";

export default {
  path: "/",
  component: Main,
  children: [
    {
      path: "/sign-up",
      component: () => import("../Pages/SignUp"),
      name: "signUp"
    },
    {
      path: "/signup-user",
      component: () => import("../Pages/SignUpUser"),
      name: "signUpUser"
    },
    {
      path: "/signup-instructor",
      component: () => import("../Pages/SignUpInstructor"),
      name: "signUpInstructor"
    },
    {
      path: "/login",
      component: () => import("../Pages/Login"),
      name: "login"
    },
    {
      path: "/forget-password",
      component: () => import("../Pages/ForgetPassword"),
      name: "forgetPassword"
    },
    {
      path: "/auth/reset/:hash",
      component: () => import("../Pages/ResetPassword"),
      name: "resetPassword"
    },
    {
      path: "/reset-password",
      component: () => import("../Pages/ResetPassword"),
      name: "resetPassword"
    }
  ]
};
