export default {
  /** @type {string} */
  message: "",

  /** @type {string} */
  img: "",
  
  /** @type {object} */
  accounts: {
    linkedin: "",
    twitter: ""
  }
};
