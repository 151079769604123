<template>
  <section class="navbar-section">
    <div class="uk-container">
      <div
        uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 50"
      >
        <nav class="uk-navbar-container uk-des-nav" uk-navbar>
          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav">
              <li>
                <router-link
                  :to="{ path: '/', hash: '#onlineCourses' }"
                  class="uk-link-nav"
                  >{{ $t("contentBar.onlineCourses") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ path: '/', hash: '#attendanceCourses' }"
                  class="uk-link-nav"
                  >{{ $t("contentBar.offlineCourses") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ path: '/learning-path' }"
                  class="uk-link-nav"
                  >{{ $t("contentBar.learningPaths") }}</router-link
                >
              </li>
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <li>
                <router-link
                  v-if="!$isLogin"
                  :to="{ name: 'login' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.login") }}</router-link
                >
              </li>
              <li>
                <router-link
                  v-if="!$isLogin"
                  :to="{ name: 'signUp' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.signup") }}</router-link
                >
              </li>
              <li>
                <router-link
                  v-if="$isLogin"
                  :to="{ name: 'editProfile' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.myAccount") }}</router-link
                >
              </li>
              <li>
                <router-link
                  to="/"
                  v-if="$isLogin"
                  class="uk-link-nav uk-link-bg"
                  ><div @click.prevent="logoutMethod">
                    {{ $t("contentBar.logout") }}
                  </div></router-link
                >
              </li>
            </ul>
          </div>
        </nav>
        <nav class="uk-navbar-container uk-mob-nav" uk-navbar>
          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav">
              <li>
                <router-link to="/" class="uk-link-nav"
                  ><img :src="logoImg" class="logo-img"
                /></router-link>
              </li>
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <li>
                <router-link
                  to=""
                  class="uk-link-nav uk-link-bg"
                  uk-toggle="target: #offcanvas-push"
                  ><span uk-icon="icon: menu; ratio: 2"></span
                ></router-link>
              </li>
            </ul>
          </div>
        </nav>
        <div
          id="offcanvas-push"
          dir="rtl"
          uk-offcanvas="mode: push; overlay: true"
        >
          <div class="uk-offcanvas-bar uk-flex uk-flex-column">
            <ul
              class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical"
            >
              <li>
                <router-link :to="{ name: 'home' }" class="uk-link-nav">{{
                  $t("navBar.mainPage")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'faqs' }" class="uk-link-nav">{{
                  $t("navBar.FAQ")
                }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'home', hash: '#onlineCourses' }"
                  class="uk-link-nav"
                >
                  {{ $t("contentBar.onlineCourses") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'home', hash: '#attendanceCourses' }"
                  class="uk-link-nav"
                >
                  {{ $t("contentBar.offlineCourses") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ path: '/learning-path' }"
                  class="uk-link-nav"
                >
                  {{ $t("contentBar.learningPaths") }}</router-link
                >
              </li>
              <li class="uk-nav-divider"></li>
              <li>
                <router-link :to="{ name: 'contactUs' }" class="uk-link-nav">{{
                  $t("navBar.contactUs")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'aboutUs' }" class="uk-link-nav">{{
                  $t("navBar.aboutBact")
                }}</router-link>
              </li>
              <li>
                <router-link
                  v-if="!$isLogin"
                  :to="{ name: 'login' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.login") }}</router-link
                >
              </li>
              <li>
                <router-link
                  v-if="!$isLogin"
                  :to="{ name: 'signUp' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.signup") }}</router-link
                >
              </li>
              <li>
                <router-link
                  v-if="$isLogin"
                  :to="{ name: 'editProfile' }"
                  class="uk-link-nav uk-link-bg"
                  >{{ $t("contentBar.myAccount") }}</router-link
                >
              </li>
              <li>
                <router-link
                  to="/"
                  v-if="$isLogin"
                  class="uk-link-nav uk-link-bg"
                  ><div @click.prevent="logoutMethod">
                    {{ $t("contentBar.logout") }}
                  </div></router-link
                >
              </li>
              <li>
                <base-badge :style="{ color: this.primaryColor }"></base-badge>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import BaseBadge from "../Base/Badge/BaseBadge";

export default {
  name: "ContentBar",

  components: {
    BaseBadge
  },

  data() {
    return {
      logoImg: require("../../assets/img/LOGO.png")
    };
  },

  methods: {
    logoutMethod() {
      localStorage.removeItem("student_token");
      localStorage.removeItem("student_loginStatus");
      localStorage.removeItem("student_refresh_token");
      this.$router.push({ name: "home" });
      window.location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
.navbar-section {
  :lang(ar) {
    direction: ltr;
  }
}
.uk-mob-nav {
  display: none;
}

.uk-navbar-right {
  margin-left: initial;
  flex-grow: 1;
  flex-direction: row-reverse;
}

.uk-des-nav {
  display: flex;
}

.uk-link-nav {
  height: 42px;
}

.uk-navbar-nav li {
  justify-content: center;
  align-items: center;
  display: flex;
}
.uk-offcanvas-overlay::before {
  background-color: var(--primary-color-overlay);
}
@media (max-width: 992px) {
  .uk-mob-nav {
    background-color: var(--white-color);
    display: flex;
  }

  .uk-des-nav {
    display: none;
  }
}
.uk-navbar-container:not(.uk-navbar-transparent) {
  background-color: var(--white-color) !important;
}
</style>
