import state from './Category.state'
import getters from './Category.getters'
import mutations from './Category.mutation'
import actions from './Category.action'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}