import state from './Students.state'
import getters from './Students.getters'
import mutations from './Students.mutation'
import actions from './Students.action'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}