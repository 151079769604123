export default {
    /**
       * @desc get categories
       * @author Obada Saleh
       * @task #BACT-73
       * */
      getInstructorInfo(state) {
        return state.instructorInfo
    }
}
