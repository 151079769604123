import Request from "@/core/Classes/Request";
import { getLectures, addNewLecture, deleteLecture, getLecture, updateLecture } from "./lectures.api";
import BaseService from "@/core/Classes/BaseService";
export default class lecturesService extends BaseService {
    /**
* @desc fetchCategories function
* @author Obada Saleh
* @task #BACT-189
* */
    static fetchLectures(id) {
        return Request.get(`${getLectures}${id}/lecture`).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }


    static fetchSingleLecture(id) {
        return Request.get(`${getLecture}${id}`).then((res) => {
            this.resolveResponse(res);
            return res;
        });
    }


    static addNewLecture(data) {
        console.log("from services", data)
        return Request.post(addNewLecture, data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم إضافة الدرس",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم إضافة الدرس",
                " حدث خطأ أثناء إضافة الدرس"
            );
            return res
        });
    }





    static deleteLecture(id) {
        return Request.delete(`${deleteLecture}${id}`).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم حذف الدرس",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم حذف الدرس",
                " حدث خطأ أثناء حذف الدرس"
            );
            return res
        });
    }
    static updateLecture(data) {
        console.log("from services", data)
        return Request.patch(`${updateLecture}${data.lectureId}`, data.data).then((res) => {
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم تعديل الوحدة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            if (res.response?.data?.response) {
                this.resolveResponse(
                    res,
                    "تم تعديل الوحدة",
                    JSON.stringify(res.response.data.response)
                  );  
              }
            this.resolveResponse(res,
                "تم تعديل الوحدة",
                " حدث خطأ أثناء تعديل الوحدة"
            );
            return res
        });
    }
}