import coursesService from "../Services/courses.service";
export default {
    fetchCourses({ commit }, query) {
        return new Promise((resolve, reject) => {
            coursesService.fetchCourses(query)
                .then((res) => {
                    commit("SET_COURSES", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchOfflineCourses({ commit }, query) {
        return new Promise((resolve, reject) => {
            coursesService.fetchCourses(query)
                .then((res) => {
                    commit("SET_OFFLINE_COURSES", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchSingleCourses({ commit }, id) {
        return new Promise((resolve, reject) => {
            coursesService.fetchSingleCourses(id)
                .then((res) => {
                    commit("SET_COURSE", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    addNewCourseInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            coursesService.addNewCourse(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    addOfflineInfo({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            coursesService.addOfflineInfo(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    updateOfflineInfo({ commit }, payload) {
        console.log("from actions", payload)
        return new Promise((resolve, reject) => {
            coursesService.updateOfflineInfo(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    updateCourseInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            coursesService.updateCourse(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    deleteCourseInstructor({ commit }, id) {
        console.log("from actions", id)
        return new Promise((resolve, reject) => {
            coursesService.deleteCourse(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    resubmitCourse({ commit }, id) {
        console.log("from actions", id)
        return new Promise((resolve, reject) => {
            coursesService.resubmitCourse(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },

}
