import Request from "../../../../../core/Classes/Request";
import { addTraningApi, getTraningsApi, editTraningApi } from "./Traning.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class TraningService extends BaseService {
    static async fetchTraning() {
        let FetchData = await Request.get(getTraningsApi);
        
        return FetchData;
    }

    static async addTraning(data) {
        
        const formdata=new FormData();
        formdata.append("title",data.title);
        formdata.append("description",data.description);
        if(data.thumbnail)
        formdata.append("thumbnail",data.thumbnail);
        let addTraning = await Request.post(addTraningApi, formdata);

        await this.resolveResponse({ ...addTraning },
            "تم أضافة المسار بنجاح",
            "حدث خطأ أثناء أضافة المسار");

        return addTraning;
    } 

    static async editTraning(data) {
     
        let url = editTraningApi
        url += `${data.id}`
        const formdata=new FormData();
        formdata.append("title",data.title);
        formdata.append("description",data.description);
        if(data.thumbnail)
        formdata.append("thumbnail",data.thumbnail);
      

        let editTraning = await Request.patch(url,formdata);
        await this.resolveResponse(editTraning,
            "تم تعديل المسار بنجاح",
            "حدث حطأ أثناء تعديل المسار");
        return editTraning;
    }
    static async deleteTraning(id) {
        let url = editTraningApi;
        url += `${id}`
        let deleteData = await Request.delete(url);
        await this.resolveResponse(deleteData,
            "تم حذف المسار بنجاح",
            "حدث خطأ اثناء حذف المسار");
        return deleteData;
    }

}