import state from './certificates.state'
import getters from './certificates.getters'
import mutations from './certificates.mutationTypes'
import actions from './certificates.actions'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}