import AuthAdminService from '../Services/authAdmin.service';

export default {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      AuthAdminService.login(data)
        .then(res => {
          console.log(res);
          commit('LOGIN', res.data.response);
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  logout({ commit }) {
    commit('LOGOUT');
  }
};
