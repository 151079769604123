import Request from "../../../core/Classes/Request";
import {
  addFAQApi,
  FAQsCount,
  getFAQsApi,
  editFAQsApi,
} from "./dashboard.api";
import BaseService from "../../../core/Classes/BaseService";

export default class DashBoardService extends BaseService {
  /**
   * @desc fetchFAQs function
   * @author Hamza Sweid
   * @task #BACT-189
   * */
  static fetchFAQs() {
    return Request.get(getFAQsApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
  /**
   * @param FAQData
   * @desc addFAQ function
   * @author Hamza Sweid
   * @task #BACT-189
   * */
  static addFAQ(data) {
    return Request.post(addFAQApi, data).then((res) => {
      this.resolveResponse(
        res,
        "تم حفظ السؤال بنجاح",
        "حدث خطأ أثناء حفظ السؤال"
      );
      return res;
    });
  }
  /**
   * @param FAQData
   * @desc addFAQ function
   * @author Hamza Sweid
   * @task #BACT-189
   * */
  static FAQsCount() {
    return Request.get(FAQsCount).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static editFAQ(data) {
    let url = editFAQsApi;
    url += `${data.id}`;
    let newdata = { question: data.question, answer: data.answer };
    return Request.patch(url, newdata).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static deleteFAQs(id) {
    let url = editFAQsApi;
    url += `${id}`;
    return Request.delete(url).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}
