import Main from "./Main.vue";

export default {
  path: "/",
  name: "Main",
  component: Main,
  children: [
    {
      path: "/home",
      name: "home",
      component: () => import("../modules/Home/Home")
    },
    {
      path: "/about-us",
      name: "aboutUs",
      component: () => import("../core/AboutUs/AboutUs.vue")
    },
    {
      path: "/contact-us",
      name: "contactUs",
      component: () => import("../core/ContactUs/ContactUs.vue")
    },
    {
      path: "/search",
      name: "search",
      component: () => import("../core/Search/Search.vue")
    }
  ]
};
