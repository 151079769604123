  import { EventBus } from "../../main";
  import i18n from "../../i18n";
  export default class BaseService {
    /**
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * @param type
     * @param message
     * */
      static showMessage(type, message) {
        EventBus.$notify({
          group: "public",
          type: type,
          title: message,
          duration: 4000
        });
      }
    /**
     * @param res
     * @param successMessage
     * @param errorMessage
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * */
    static resolveResponse(res, successMessage, errorMessage) {
      if (res.status < 300) {
        this.showMessage("success", i18n.t(successMessage));
      } else {
        this.showMessage("error", i18n.t(errorMessage));
      }
    }
  }
