import CategoriesService from '../Services/Categories.services'
export default {

    async fetchData({ commit }) {
        commit("SET_LOADING",true)
        try {
            let response = await CategoriesService.fetchData();
            await commit("SET_DATA", response.data.response);
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async addCategory({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await CategoriesService.addCategory(data);
            await dispatch('fetchData');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
   
    async editCategory({ commit, dispatch }, {id, data}) {
        commit("SET_LOADING",true)
        try {
            let response = await CategoriesService.editCategory(id, data);
            await dispatch('fetchData');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
  
    async deleteCategory({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await CategoriesService.deleteCategory(data);
            await dispatch('fetchData');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)
    },




}