import Request from "../../../../../../../core/Classes/Request";
import { instructorCoursesApi } from "./Courses.api";
import BaseService from "../../../../../../../core/Classes/BaseService";

export default class CoursesService extends BaseService {
   //        INSTRUCTOR COURSES SECTION

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructor's courses list 
   */
  static fetchCourses (id) {
    console.log(instructorCoursesApi + id + '/courses')
    return Request.get(instructorCoursesApi + id + '/courses?page=0&size=20').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's courses
   */
  static fetchCoursesWithSearch (data) { 
    let a = `/courses?page=0&size=20&q=`
    console.log(instructorCoursesApi + data.id + a + data.searchTerm)
    return Request.get(instructorCoursesApi + data.id + a + data.searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
   //        PENDING COURSES SECTION

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructor's courses list 
   */
  static fetchPendingCourses (id) {
    console.log(instructorCoursesApi + id + '/courses/pending')
    return Request.get(instructorCoursesApi + id + '/courses/pending?page=0&size=20').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's courses
   */
  static fetchPendingCoursesWithSearch (data) { 
    let a = `/courses/pending?page=0&size=20`
    console.log(instructorCoursesApi + data.id + a + data.searchTerm)
    return Request.get(instructorCoursesApi + data.id + a + data.searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the Instructor's courses list 
   */
  static fetchPendingCoursesCount (id) {
    console.log(instructorCoursesApi + id + '/courses/pending-count')
    return Request.get(instructorCoursesApi + id + '/courses/pending-count').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }
}