<template>
  <v-app :lang="getLang">
    <router-view></router-view>
    <!-- request-loader -->
    <request-loader />
  </v-app>
</template>

<script>
import RequestLoader from "./core/RequestLoader/RequestLoader.vue";
export default {
  name: "App",

  components: {
    RequestLoader
  },

  data: () => ({
    //
  }),

  computed: {
    getLang() {
      return this.$store.state.rtl === false ? "ar" : "en";
    }
  }
};
</script>
<style>
.v-application {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
/*@import './styles';*/
@import "./assets/css/uikit.min.css";
@import "./assets/css/main.css";
@import "./assets/css/styleAr.css";
</style>
