export default {
  getCourses(state) {
    return state.courses;
  },
  getPendingCourses(state) {
    return state.pendingCourses;
  },
  getPendingCoursesCount (state) {
    return state.pendingCoursesCount;
  }
}