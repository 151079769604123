export default {
  getIsLogIn(state) {
    return state.isAdminLogin;
  },
  getToken(state) {
    return state.token;
  },
  getAdminType (state) {
    return state.adminType;
  }
}
