export default {
  /** @type {array} */
  sliders: [],

  /** @type {array} */
  onlineCourses: [],

  /** @type {array} */
  offlineCourses: [],

  /** @type {array} */
  courseCategories: [],

  /** @type {array} */
  partners: []
};
