import Request from "../../../core/Classes/Request";
import BaseService from "../../../core/Classes/BaseService";
import addFAQApi from "./FAQs.api";

export default class FAQsService extends BaseService {
  /**
   * @author Ibrahim Zanbily
   * @task #BACT-189-test2
   * */
  static fetchFAQs() {
    return Request.get(addFAQApi).then((res) => {
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-189-test2
   * @desc search the FAQs
   */
  static fetchFAQsWithSearch(searchTerm) {
    console.log(addFAQApi + "&q=" + searchTerm);
    return Request.get(addFAQApi + "&q=" + searchTerm).then((res) => {
      return res;
    });
  }
}
