/**
 * @desc dashboard module apis routes
 * @author karam mustafa
 * @task #BACT-54
 * */

export default {
  path: '/instructor-dashboard',
  name: 'instructorDashboard',
  redirect: {
    name: 'instructorDashboardMain'
  },
  component: () => import('../instructorDashBoard'),
  children: [
    {
      path: 'main',
      name: 'instructorDashboardMain',
      component: () => import('../Pages/Main'),
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'info',
      name: 'instructorDashboardInfo',
      component: () => import('../Pages/Info/'),
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'courses',
      name: 'coursesIndex',
      component: () => import('../Pages/Course'),
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'course/new',
      component: () => import('../Pages/Course/Pages/AddEditCourse'),
      name: 'addNewCourse',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'course/update/:id',
      component: () => import('../Pages/Course/Pages/AddEditCourse'),
      name: 'updateCourse',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'course/show/:id',
      component: () => import('../Pages/Course/Pages/AddEditCourse'),
      name: 'showCourse',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'offline/new/:id',
      component: () => import('../Pages/Course/Pages/AddOffline'),
      name: 'addOffline',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'modules/:id',
      component: () => import('../Pages/Module'),
      name: 'modulesIndex',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'module/new/:id',
      component: () => import('../Pages/Module/Pages/AddEditModule'),
      name: 'addNewModule',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'module/add-translation/:id',
      component: () => import('../Pages/Module/Pages/AddEditModule'),
      name: 'addModuleTranslation',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'module/update/:id',
      component: () => import('../Pages/Module/Pages/AddEditModule'),
      name: 'editModuleInstructor',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'module/show/:id',
      component: () => import('../Pages/Module/Pages/AddEditModule'),
      name: 'showModule',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'lectures/:id',
      component: () => import('../Pages/Lecture'),
      name: 'lecturesIndex',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'lecture/new/:id',
      component: () => import('../Pages/Lecture/Pages/AddEditLecture'),
      name: 'addNewLecture',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'lecture/show/:id',
      component: () => import('../Pages/Lecture/Pages/AddEditLecture'),
      name: 'showLecture',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path: 'lecture/update/:id',
      component: () => import('../Pages/Lecture/Pages/AddEditLecture'),
      name: 'editLecture',
      meta: {
        requiresInstructorAuth: true
      }
    },
    {
      path : 'instructor-module-questions/:id',
      name: 'instructorModuleQuestions',
      component: () => import('../Pages/Module/Pages/Questions/index'),
      meta: {
        requiresInstructorAuth: true
      }
    },
  ]
};
