import QuestionsService from "../Services/Questions.service";

export default {
 
  fetchQuestions ({ commit }, id) {
    return new Promise((resolve, reject) => {
      QuestionsService.fetchQuestions(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_MODULE", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addQuestion ({ _, dispatch }, data) {
    return new Promise((resolve, reject) => {
      QuestionsService.addQuestion(data)
        .then((res) => {
          // dispatch('fetchData', payload.id);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  addAnswers ({ _, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      QuestionsService.addAnswers(payload)
        .then((res) => {
          // dispatch('fetchData', payload.id);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  updateQuestion({ _, dispatch }, payload ) {
    return new Promise((resolve, reject) => {
      QuestionsService.updateQuestion(payload)
        .then((response) => {
          // dispatch('fetchSingleInstructor', payload.id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateAnswer({ _, dispatch }, payload ) {
    return new Promise((resolve, reject) => {
      QuestionsService.updateAnswer(payload)
        .then((response) => {
          // dispatch('fetchSingleInstructor', payload.id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteQuestion ({ _, dispatch }, id) {
    return new Promise((resolve, reject) => {
      QuestionsService.deleteQuestion(id)
        .then((response) => {
          // dispatch('fetchInstructors');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteAnswer ({ _, dispatch }, id) {
    return new Promise((resolve, reject) => {
      QuestionsService.deleteAnswer(id)
        .then((response) => {
          // dispatch('fetchInstructors');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
}