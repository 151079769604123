import StudentsService from "../Services/Students.service";

export default {
  //        INSTRUCTOR Students SECTION
  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc get the contact messages 
   */
  fetchStudents({ commit }, id) {
    return new Promise((resolve, reject) => {
      StudentsService.fetchStudents(id)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_STUDENTS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-208-test 
   * @desc search the instructor's Students 
   */
  fetchStudentsWithSearch ({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      StudentsService.fetchStudentsWithSearch(data)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_STUDENTS", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}