export default {
  /**
   * @param state
   * @param imgAndMessage
   * @desc GET_IMG_AND_MSG mutation
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  GET_IMG_AND_MSG(state, response) {
    state.img = response.img;
    state.message = response.message;
  },

  /**
   * @param state
   * @param linkedAndTwitter
   * @desc GET_IMG_AND_MSG mutation
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  GET_ACCOUNTS(state, response) {
    state.accounts.linkedin = response;
    state.accounts.twitter = response;
  }
};
