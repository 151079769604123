import HomeService from "../Services/home.service";

export default {
  /**
   * @param commit
   * @desc getSliders function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  getSliders({ commit }) {
    return new Promise((resolve, reject) => {
      HomeService.getSliders()
        .then((res) => {
          commit("SET_SLIDRES", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getOnlineCourses function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  getOnlineCourses({ commit }) {
    return new Promise((resolve, reject) => {
      HomeService.getOnlineCourses()
        .then((res) => {
          commit("SET_ONLINE_COURSES", res.data.response.online_courses);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getOfflineCourses function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  getOfflineCourses({ commit }) {
    return new Promise((resolve, reject) => {
      HomeService.getOfflineCourses()
        .then((res) => {
          commit("SET_OFFLINE_COURSES", res.data.response.offline_courses);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getCourseCategories function
   * @author Hamza Sweid
   * @task #BACT-176
   * */
  fetchCourseCategories({ commit }) {
    return new Promise((resolve, reject) => {
      HomeService.fetchCourseCategories()
        .then((res) => {
          commit("SET_COURSE_CATEGORIES", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  /**
   * @param commit
   * @desc getOurPartners function
   * @author Hamza Sweid
   * @task #BACT-188
   * */
  getOurPartners({ commit }) {
    return new Promise((resolve, reject) => {
      HomeService.getOurPartners()
        .then((res) => {
          commit("SET_OUR_PARTNERS", res.data.response);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
