export default class Dashboard {
  fetchCertificates() {
    const certificates = [
      {
        studentName: 'Cool Name',
        email: 'coolname@gmail.com',
        course: 'Graphic Design',
        enrollDate: '2020-01-21',
        awardDate: '2020-02-23',
        result: '95%',
      },
      {
        studentName: 'Not Cool Name',
        email: 'notcoolname@gmail.com',
        course: 'Frontend',
        enrollDate: '2020-02-21',
        awardDate: '2020-03-23',
        result: '70%',
      },
    ];

    return Promise.resolve({ data: certificates });
  }

  fetchFAQs() {
    const FAQs = [
      {
        question: 'ما هي BACT؟',
        answer: 'باكت هي موقع تعليمي',
        addedAt: '2020-01-21',
      },
      {
        question: 'من يمكنه إنشاء محتوى؟',
        answer: 'أي شخص قام بالتسجيل بحساب مدرس',
        addedAt: '2020-01-21',
      },
    ];

    return Promise.resolve({ data: FAQs });
  }
}