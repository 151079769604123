import state from  './FAQs.state';
import actions from './FAQs.actions'
import mutations from './FAQs.mutations'
import getters from  './FAQs.getters';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}