import state from './modules.state';
import actions from './modules.action';
import mutations from './modules.mutationTypes';
import getters from './modules.getters';
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author Obada Saleh
 * @task #BACT-73
 * */
export default{
  state,
  actions,
  mutations,
  getters,
  modules:{}
}
