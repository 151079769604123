import state from './instructors.state'
import getters from './instructors.getters'
import mutations from './instructors.mutationTypes'
import actions from './instructors.actions'
import Courses from '../Pages/Courses/Store/index'
import Students from '../Pages/Students/Store/index'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{
        Courses,
        Students
    }
}