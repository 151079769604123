import Request from "../../Classes/Request";
import {
  courseModalApi,
  addCourseToWishListApi,
  enrollCourse_1,
  enrollCourse_2
} from "./courseInfoModal.api";
import BaseService from "../../Classes/BaseService";

export default class CourseInfoModalService extends BaseService {
  /**
   * @param _
   * @desc selectCourseModal function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  static selectCourseModal(id) {
    return Request.get(`${courseModalApi}${id}`).then((res) => {
      return res;
    });
  }

  /**
   * @param course_id
   * @desc addCourseToWishList function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  static addCourseToWishList(id) {
    return Request.post(addCourseToWishListApi, id).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.addedToWishList",
        "resolveResponse.pleaseSignInFirst"
      );
      return res;
    });
  }

  /**
   * @param course_id
   * @desc enrollCourse function
   * @author Hamza Sweid
   * @task #BACT-196
   * */
  static enrollCourse(id) {
    return Request.post(enrollCourse_1 + id + enrollCourse_2).then((res) => {
      this.resolveResponse(
        res,
        "resolveResponse.enrolledSuccessfuly",
        "resolveResponse.pleaseSignInFirst"
      );
      return res;
    });
  }
}
