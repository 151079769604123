import state from './instructorDashBoard.state';
import actions from './instructorDashBoard.action';
import mutations from './instructorDashBoard.mutationTypes';
import getters from './instructorDashBoard.getters';
/**
 * @param mixed commit
 * @param data
 * @desc login function
 * @author karam mustafa
 * @task #BACT-73
 * */
export default{
  state,
  actions,
  mutations,
  getters,
  modules:{}
}
