// Main Dashboard API
export const CountersApi = "api/admin/dashboard-counters";
export const PendingApi = "api/course/admin/pending"; // ?page=0&size=8&q=very new
export const PendingCountApi = "api/course/admin/count-pending"; // ?page=0&size=8&q=very new
export const TopCoursesApi = "api/admin/top-courses"; // ?top=3
export const EnrollPerformanceApi = "api/admin/enrolling-preformance"; // ?period=year

export const offlineApi = "api/course/offline"

export const statusApi = "api/course/admin/change-status"

export const liveCoursesApi = "api/course/admin/live?page=0&size=50"

export const deniedCoursesApi = "api/course/admin/denied?page=0&size=50"
