export default {
    /**
       * @desc get categories
       * @author Obada Saleh
       * @task #BACT-73
       * */
    getCategories(state) {
        return state.categoriesArray;
    },
    getCountersInstructor(state) {
        return state.countersInstructor
    },
    getStudentsInstructor(state) {
        return state.studentsInstructor
    },
}
