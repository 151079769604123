import Request from "../../../../../core/Classes/Request";
import { getImgAndMsg } from "./aboutUs.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class AboutUsService extends BaseService {
   
  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc get the about bact information (Image and Message)  
   */
  static getImgAndMsg () {
    return Request.get(getImgAndMsg).then((res) => {
      console.log('get service')
      console.log(res)
      return res;
    });
  }
  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc update the about bact information (Image and Message)  
   */
  static updateAboutUs (data) {
    console.log('service')
    console.log(data)
    const formdata=new FormData();
    formdata.append("message",data.message);
    if(data.img)
    formdata.append("img",data.img);
    console.log(typeof(formdata))
    return Request.patch(getImgAndMsg, formdata).then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    })
  }

}