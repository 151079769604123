export const addFAQApi = "api/pickListItems/faqs";
export const getFAQsApi='api/pickListItems/faqs?page=0&size=111'
// export const addFAQApi = "api/pickListItems/faqs";
export const FAQsCount = "api/pickListItems/faqs/count";
export const editFAQsApi="api/pickListItems/faqs/";

// Sliders
export const getSlidersAPI = "https://bact-back.usol.ca/api/slider";
export const addSliderAPI = "https://bact-back.usol.ca/api/slider";
export const editSliderAPI = "https://bact-back.usol.ca/api/slider/";
export const deleteSliderAPI = "https://bact-back.usol.ca/api/slider/";

// Super Admin
export const updateNormalSuperAdminAPI = "https://bact-back.usol.ca/api/admin/";
export const createNormalSuperAdminAPI = "https://bact-back.usol.ca/api/admin";
export const deleteNormalSuperAdminAPI = "https://bact-back.usol.ca/api/admin/";
export const getAllAdminsAPI = "https://bact-back.usol.ca/api/admin?page=0&size=8"
