export default {
  SET_COUNTERS(state, counters) {
    state.counters = counters;
  },
  SET_PENDING_COURSES(state, pendingCourses) {
    state.pendingCourses = pendingCourses;
  },
  SET_LOADING_STATUS (state, status) {
    state.loadingPendingCourses = status
  },
  SET_PENDING_COURSES_COUNT (state, data) {
    state.pendingCoursesCount = data.pending_count
  },
  SET_TOP_COURSES (state, topCourses) {
    state.topCourses = topCourses
  },
  SET_ENROLLMENT_PERFORMANCE (state, enrollmentPeroformance) {
    state.enrollmentPeroformance = enrollmentPeroformance
  },
  SET_LIVE_COURSES (state, data) {
    state.liveCourses = data;
  },
  SET_DENIED_COURSES (state, data) {
    state.deniedCourses = data;
  }
}