import ModulesService from "../Services/Modules.service";

export default {
 
  fetchData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ModulesService.fetchData(payload)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_DATA", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSingleData ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ModulesService.fetchSingleData(payload)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_SINGLE_DATA", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addData ({ _, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ModulesService.addData(payload)
        .then((res) => {
          // dispatch('fetchData', payload.id);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  updateData({ _, dispatch }, payload ) {
    return new Promise((resolve, reject) => {
      ModulesService.updateData(payload)
        .then((response) => {
          // dispatch('fetchSingleInstructor', payload.id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteData ({ _, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ModulesService.deleteData(payload)
        .then((response) => {
          // dispatch('fetchInstructors');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
}