import Request from "../../../core/Classes/Request";
import {forgetPasswordApi, loginApi, registerApi, resetPasswordApi} from "./auth.api";
import BaseService from "../../../core/Classes/BaseService";

export default class AuthService extends BaseService{
    /**
     * @param data
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * */
    static login(data) {
        return Request.post(loginApi, data).then((res) => {
            this.resolveResponse(
              res,
              "resolveResponse.logInSuccessfuly",
              "resolveResponse.emailOrPasswordIsIncorrect"
            );
            return res;
        })
    }

    /**
     * @param data
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * */
    static register(data) {
        return Request.post(registerApi, data).then((res) => {
            // this.resolveResponse(res,
            //     'resolveResponse.signUpSuccessfuly'
            //     , 'resolveResponse.failedToCreateAccount');
            return res;
        })
    }
    /**
     * @param data
     * @desc forget password function
     * @author karam mustafa
     * @task #BACT-101
     * */
    static forgetPassword(data) {
        return Request.post(forgetPasswordApi, data).then((res) => {
            this.resolveResponse(res,
                'resolveResponse.linkSentToYourEmail'
                , 'resolveResponse.somethingWentWrong');
            return res;
        })
    }
    /**
     * @param data
     * @desc reset password function
     * @author karam mustafa
     * @task #BACT-101
     * */
    static resetPassword(data) {
        let newResetPasswordApi = `${resetPasswordApi}/${data.hash}`;
        return Request.post(newResetPasswordApi, data).then((res) => {
            this.resolveResponse(
              res,
              "resolveResponse.YourPasswordHasChange",
              "resolveResponse.somethingWentWrong"
            );
            return res;
        })
    }
}
