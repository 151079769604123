import Request from "../../../../../core/Classes/Request";
import { CountersApi, PendingApi, PendingCountApi, TopCoursesApi, EnrollPerformanceApi, offlineApi, statusApi, liveCoursesApi, deniedCoursesApi } from "./main.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class MainService extends BaseService {
  
  /**
   * @desc fetch Dashboard Counters
   * @author Ibrahim Zanbily
   * @task #BACT-219-test
   * */
  static fetchCounters() {
    return Request.get(CountersApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
  
  /**
   * @desc fetch Dashboard pending courses
   * @author Ibrahim Zanbily
   * @task #BACT-219-test
   * */
  static fetchPendingCourses() {
    console.log('main')
    return Request.get(PendingApi + '?page=0&size=50').then((res) => {
      console.log(res)
      this.resolveResponse(res);
      return res;
    });
  }
  /**
   * @desc fetch Dashboard pending courses count
   * @author Ibrahim Zanbily
   * @task #BACT-219-test
   * */
  static fetchPendingCoursesCount() {
    return Request.get(PendingCountApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @author Ibrahim Zanbily
   * @task BACT-219-test 
   * @desc search the pending courses 
   */
  static fetchPendingCoursesWithSearch (searchTerm) { 
    let a = '?page=0&size=50&q='
    return Request.get(PendingApi + a + searchTerm).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @desc fetch Top Courses 
   * @author Ibrahim Zanbily
   * @task #BACT-219-test
   * */
  static fetchTopCourses(coursesNumber) {
    let a = ''; // to send the query and the default is 3
    if(coursesNumber)
    a = `?top=${coursesNumber}`
    return Request.get(TopCoursesApi + a).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  /**
   * @desc fetch Enrollment Performance 
   * @author Ibrahim Zanbily
   * @task #BACT-219-test
   * */
  static fetchEnrollmentPeroformance(period) {
    let a = ''; // to send the query and the default is 3
    if(period)
    a = '?period=' + period;
    return Request.get(EnrollPerformanceApi + a).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static updateStatus (data) {
 
    return Request.patch(statusApi, data).then((res) => {
      if (res.response) {
        if (res.response.data.response === 'course was not found') {
          this.resolveResponse(
            res,
            "تم تعديل الحالة بنجاح",
            'لم يتم العثور على الدورة'
          );  
        } else {
          this.resolveResponse(
            res,
            "تم تعديل الحالة بنجاح",
            JSON.stringify(res.response.data)
          );  
        }
      }
      this.resolveResponse(
        res,
        "تم تعديل الحالة بنجاح",
        "حدث خطأ أثناء تعديل الحالة"
      );
      return res;
    });
  }

  static fetchLiveCourses() {
    return Request.get(liveCoursesApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }

  static fetchDeniedCourses() {
    return Request.get(deniedCoursesApi).then((res) => {
      this.resolveResponse(res);
      return res;
    });
  }
}