import actions from "./Profile.actions";
import mutations from "./Profile.mutationTypes";
import getters from "./Profile.getters";
import state from "./Profile.state";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
