export default {
  /**
   * @param state
   * @param response
   * @desc SET_SEARCH function
   * @author Hamza Sweid
   * @task #BACT-223
   * */
  SET_SEARCH(state, response) {
    state.categories = response.categories;
    state.trainingPaths = response.training_paths;
    state.courses = response.courses;
  }
};
