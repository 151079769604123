export const fetchModulesApi = "api/course/";  // :courseId/module/
export const createModuleApi = "api/module";
export const getSingleModuleApi = "api/modules/info/"; // :moduleId
export const editDeleteModuleApi = "api/admin/module/"; //:moduleId

export const fetchLecturesApi = "api/module/"; //:module_id/lecture/
export const createLectureApi = "api/lecture";
export const fetchSingleLectureApi = "api/lecture/"; //:id/getLecture
export const editDeleteLectureApi = "api/admin/lecture/"; //:lectureId

