export default {
  LOGIN(state, data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('adminLoginStatus', true);
    localStorage.setItem('type', data.type);
    localStorage.setItem('adminType', data.role);
    state.token = data.token;
    state.isAdminLogin = true;
    state.adminType = data.role;
  },
  LOGOUT(state) {
    localStorage.setItem('token', null);
    localStorage.setItem('adminLoginStatus', false);
    localStorage.setItem('type', null);
    localStorage.setItem('adminType', null);
    state.token = null;
    state.isAdminLogin = false;
    state.adminType = null;
  },
};
