import ContactUsService from "../Services/contactUs.service";

export default {
  
  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc get the contact messages 
   */
  fetchContactMessages({ commit }) {
    return new Promise((resolve, reject) => {
      ContactUsService.fetchContactMessages()
        .then((response) => {
          console.log(response.data.response)
          commit("SET_CONTACT_MESSAGES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * @author Ibrahim Zanbily
   * @task BACT-207-test 
   * @desc search the contact messages 
   */
  fetchContactMessagesWithSearch ({ commit }, data) {
    console.log(data.searchTerm)
    return new Promise((resolve, reject) => {
      ContactUsService.fetchContactMessagesWithSearch(data.searchTerm)
        .then((response) => {
          console.log(response.data.response)
          commit("SET_CONTACT_MESSAGES", response.data.response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteMessage ({ _, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ContactUsService.deleteMessage(data.id)
        .then((response) => {
          dispatch('fetchContactMessages');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}