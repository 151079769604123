import state from "./trainingPathList.state";
import getters from "./trainingPathList.getters";
import mutations from "./trainingPathList.mutationTypes";
import actions from "./trainingPathList.actions";
export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
};
