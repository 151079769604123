<template>
  <ul class="uk-navbar-nav">
    <li>
      <router-link :to="{ name: 'home' }" class="uk-link-nav">{{
        $t("navBar.mainPage")
      }}</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'faqs' }" class="uk-link-nav">{{
        $t("navBar.FAQ")
      }}</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'contactUs' }" class="uk-link-nav">{{
        $t("navBar.contactUs")
      }}</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'aboutUs' }" class="uk-link-nav">{{
        $t("navBar.aboutBact")
      }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavBarLinks"
};
</script>

<style scoped></style>
