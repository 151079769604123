
export default {
  SET_TRANING(state, Traning) {
    state.Traning = Traning;
  },
  SET_LOADING(state, isloading) {
    state.loadingTraning = isloading;
  },
  SET_IMAGE(state, image) {
    state.imageSorce = image;
  }

}