import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Request from "../core/Classes/Request";

Vue.use(VueRouter);

/*
 * Author: Ali Ataf
 * get [<filename>.routes.js] files content from modules directory and put
 * them in routesModules array
 */
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);

const routes = [
  {
    path: "/",
    component: Main,
    redirect: "/home"
  },
  ...routesModules,
  {
    path: "*",
    redirect: "/home"
  }
];

const router = new VueRouter({
  scrollBehavior: function(to) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ selector: to.hash, offset: { x: 0, y: 70 } });
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 800);
    });
  },

  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  // refresh token
  let dashboard = to.fullPath.indexOf("dashboard") !== -1;

  // if student page
  console.log(dashboard, to);
  if (!dashboard) {
    if (localStorage.getItem("student_refresh_token")) {
      let data = {
        refresh_token: localStorage.getItem("student_refresh_token")
      };
      Request.post("auth/token", data)
        .then((res) => {
          localStorage.setItem("student_token", res.data.response.token);
        })
        .catch((err) => console.log(err));
    }
  }

  // handle authentication for dashboard roles
  const role = localStorage.getItem("adminType");
  if (to.matched.some((rec) => rec.meta.requiresAdminAuth)) {
    if (role === "super" || role === "normal") {
      next();
    } else if (role === "editor") {
      next({ name: "main" });
    } else {
      next({ name: "admin login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresEditorAuth)) {
    if (role === "editor" || role === "super" || role === "normal") {
      next();
    } else {
      next({ name: "admin login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresInstructorAuth)) {
    if (localStorage.getItem("type") === "instructor") {
      next();
    } else {
      next({ name: "instructor login" });
    }
  } else {
    next();
  }
});
export default router;
