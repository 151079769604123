import TraningService from '../Services/Traning.services'
export default {

    async fetchTraning({ commit }) {
        commit("SET_LOADING",true)
        try {
            
            let response = await TraningService.fetchTraning();
            await commit("SET_TRANING", response.data.response);
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
    async addTraning({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await TraningService.addTraning(data);
            await dispatch('fetchTraning');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
   
    async editTraning({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await TraningService.editTraning(data);
            await dispatch('fetchTraning');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)

    },
  
    async delete({ commit, dispatch }, data) {
        commit("SET_LOADING",true)
        try {
            let response = await TraningService.deleteTraning(data);
            await dispatch('fetchTraning');
        } catch (error) {
            console.log(error);
        }
        commit("SET_LOADING",false)
    },




}