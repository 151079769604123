import Request from "../../../../../core/Classes/Request";
import {
  updateNormalSuperAdminAPI,
  deleteNormalSuperAdminAPI,
  getAllAdminsAPI,
  createNormalSuperAdminAPI,
} from "./Admins.api";
import BaseService from "../../../../../core/Classes/BaseService";

export default class AdminsService extends BaseService {
  static updateNormalSuperAdmin(data) {
    console.log(data.avatar)
    console.log(updateNormalSuperAdminAPI + data.id);
    const formdata = new FormData();
    formdata.append("first_name", data.firstName);
    formdata.append("last_name", data.lastName);
    formdata.append("phone", data.phone);
    formdata.append("email", data.email);
    formdata.append("country", data.country);
    formdata.append("identity_number", data.identityNumber);
    formdata.append("birth_date", data.birthDate);
    // formdata.append("role", data.role);
    if (data.avatar && typeof(data.avatar) !== 'string') formdata.append("avatar", data.avatar);
    console.log(formdata);
    console.log(data);
    return Request.patch(
      updateNormalSuperAdminAPI + data.id,
      formdata
    ).then((res) => {
      if (res.response?.data?.response) {
        this.resolveResponse(
            res,
            "تم تعديل المسؤول بنجاح",
            JSON.stringify(res.response.data.response)
          );  
      }
      this.resolveResponse(
        res,
        "تم تعديل المسؤول بنجاح",
        "حدث حطأ أثناء تعديل المسؤول"
      );
      console.log(formdata);
      console.log(res);
      return res;
    });
  }

  static createNormalSuperAdmin(data) {
    console.log(createNormalSuperAdminAPI);
    const formdata = new FormData();
    formdata.append("first_name", data.firstName);
    formdata.append("last_name", data.lastName);
    formdata.append("phone", data.phone);
    formdata.append("email", data.email);
    formdata.append("country", data.country);
    formdata.append("identity_number", data.identityNumber);
    formdata.append("birth_date", data.birthDate);
    formdata.append("password", data.password);
    formdata.append("role", data.role);
    if (data.avatar) formdata.append("avatar", data.avatar);
    console.log(formdata);
    console.log(data);
    return Request.post(createNormalSuperAdminAPI, formdata).then((res) => {
      if (res.response?.data?.response) {
        if (res.response?.data?.response) {
          this.resolveResponse(
              res,
              "تم أضافة المسؤول بنجاح",
              JSON.stringify(res.response.data.response)
            );  
        }
        this.resolveResponse(
            res,
            "تم أضافة المسؤول بنجاح",
            JSON.stringify(res.response.data.response)
          );  
      }
      this.resolveResponse(
        res,
        "تم أضافة المسؤول بنجاح",
        "حدث خطأ أثناء أضافة المسؤول"
      );
      console.log(formdata);
      console.log(res);
      return res;
    });
  }

  static deleteNormalSuperAdmin(id) {
    console.log(deleteNormalSuperAdminAPI + id);
    return Request.delete(deleteNormalSuperAdminAPI + id).then((res) => {
      if (res.response?.data?.response) {
        this.resolveResponse(
            res,
            "تم حذف المسؤول بنجاح",
            JSON.stringify(res.response.data.response)
          );  
      }
      this.resolveResponse(
        res,
        "تم حذف المسؤول بنجاح",
        "حدث خطأ أثناء حذف المسؤول"
      );
      return res;
    });
  }

  static getAllAdmins() {
    return Request.get(getAllAdminsAPI).then((res) => {
      this.resolveResponse(res , "تم عرض المسؤولين بنجاح");
      return res;
    });
  }
}
