import modulesService from "../Services/modules.service";
export default {
    fetchModules({ commit }, id) {
        return new Promise((resolve, reject) => {
            modulesService.fetchModules(id)
                .then((res) => {
                    commit("SET_MODULES", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    fetchSingleModule({ commit }, id) {
        return new Promise((resolve, reject) => {
            modulesService.fetchSingleModule(id)
                .then((res) => {
                    commit("SET_MODULE", res.data.response);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    addNewModuleInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            modulesService.addNewModule(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    updateModuleInstructor({ commit }, data) {
        console.log("from actions", data)
        return new Promise((resolve, reject) => {
            modulesService.updateModule(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    deleteModuleInstructor({ commit }, id) {
        console.log("from actions", id)
        return new Promise((resolve, reject) => {
            modulesService.deleteModule(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
}
